
import WWidgetBase from '@/editor/components/widgets/WWidgetBase.vue';

export default WWidgetBase.extend({
	name: 'WWidgetGoogleMap',
	props: ['embedId'],
	computed: {
		mapUrl: function () {
			return 'https://www.google.com/maps/d/u/0/embed?mid=' + this.embedId;
		},
	},
});
