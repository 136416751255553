
import {buildWgtFormOptions, typesWidgets} from '../utils';

import BaseComponent from '@/components/BaseComponent.vue';

export default BaseComponent.extend({
	name: 'WEditorWidgetOptions',
	props: {
		options: {
			required: true,
			type: Object,
		},
		value: Object,
		targetRef: {
			required: true,
			type: String,
		},
	},
	computed: {
		formFields() {
			return buildWgtFormOptions(this.options, this.value);
		}
	},
	methods: {
		onFormUpdate: function (field: any, value: any) {
			this.$emit('input', {...this.value, [field.name]: value});
		},
		getTypeWgt: function (name: string) {
			return typesWidgets.get(name);
		},
	},
});
