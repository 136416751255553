
/**
 * Auto generated file,
 *
 * INFO: you are free to edit it,
 * but make sure to know what you are doing.
 *
 * Proudly With: gobl v1.5.0
 * Time: 1650839726
 */

import { getEntityCache, GoblEntityData } from "gobl-utils-ts";
import OZCountryBase from "./base/OZCountryBase";

export default class OZCountry extends OZCountryBase {
	constructor(data?: GoblEntityData) {
		super(data, "OZCountry", OZCountry.PREFIX, OZCountry.COLUMNS);
	}

	public static fromCache(cacheKey: string): OZCountry | undefined {
		const cache: any = getEntityCache("OZCountry");
		return cache && cache[cacheKey];
	}

	//====================================================
	//=	Your custom implementation goes here
	//====================================================
}
