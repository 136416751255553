
/**
 * Auto generated file
 *
 * WARNING: please don't edit.
 *
 * Proudly With: gobl v1.5.0
 * Time: 1651227193
 */

import {
	GoblEntity,
	_bool,
	_int,
	_string,
} from 'gobl-utils-ts';

export default abstract class UCMessageLikeBase extends GoblEntity {
	public static readonly PREFIX: string = 'ml';
	public static readonly COLUMNS: string[] = [
	'ml_id',
	'ml_message_id',
	'ml_user_id',
	'ml_custom_data',
	'ml_create_time',
	'ml_update_time',
	'ml_valid',

	];
	public static readonly COL_ID: string = 'ml_id';
	public static readonly COL_MESSAGE_ID: string = 'ml_message_id';
	public static readonly COL_USER_ID: string = 'ml_user_id';
	public static readonly COL_CUSTOM_DATA: string = 'ml_custom_data';
	public static readonly COL_CREATE_TIME: string = 'ml_create_time';
	public static readonly COL_UPDATE_TIME: string = 'ml_update_time';
	public static readonly COL_VALID: string = 'ml_valid';

	public singlePKValue() {
		return this.id;
	}
	public identifierColumns() {
		return [ UCMessageLikeBase.COL_ID ];
	}
	get id() { return _string(this._data[UCMessageLikeBase.COL_ID]); }
	set id(nVal: any) { this._set(UCMessageLikeBase.COL_ID, _string(nVal)); }
	public getId() { return this.id; }
	public setId(nVal: any): this { this.id = nVal; return this; }

	get message_id() { return _string(this._data[UCMessageLikeBase.COL_MESSAGE_ID]); }
	set message_id(nVal: any) { this._set(UCMessageLikeBase.COL_MESSAGE_ID, _string(nVal)); }
	public getMessageId() { return this.message_id; }
	public setMessageId(nVal: any): this { this.message_id = nVal; return this; }

	get user_id() { return _string(this._data[UCMessageLikeBase.COL_USER_ID]); }
	set user_id(nVal: any) { this._set(UCMessageLikeBase.COL_USER_ID, _string(nVal)); }
	public getUserId() { return this.user_id; }
	public setUserId(nVal: any): this { this.user_id = nVal; return this; }

	get custom_data() { return _string(this._data[UCMessageLikeBase.COL_CUSTOM_DATA]); }
	set custom_data(nVal: any) { this._set(UCMessageLikeBase.COL_CUSTOM_DATA, _string(nVal)); }
	public getCustomData() { return this.custom_data; }
	public setCustomData(nVal: any): this { this.custom_data = nVal; return this; }

	get create_time() { return _string(this._data[UCMessageLikeBase.COL_CREATE_TIME]); }
	set create_time(nVal: any) { this._set(UCMessageLikeBase.COL_CREATE_TIME, _string(nVal)); }
	public getCreateTime() { return this.create_time; }
	public setCreateTime(nVal: any): this { this.create_time = nVal; return this; }

	get update_time() { return _string(this._data[UCMessageLikeBase.COL_UPDATE_TIME]); }
	set update_time(nVal: any) { this._set(UCMessageLikeBase.COL_UPDATE_TIME, _string(nVal)); }
	public getUpdateTime() { return this.update_time; }
	public setUpdateTime(nVal: any): this { this.update_time = nVal; return this; }

	get valid() { return _bool(this._data[UCMessageLikeBase.COL_VALID]); }
	set valid(nVal: any) { this._set(UCMessageLikeBase.COL_VALID, _bool(nVal)); }
	public getValid() { return this.valid; }
	public setValid(nVal: any): this { this.valid = nVal; return this; }


}
