
import Vue from 'vue';
import app from '@/app';

export default Vue.extend({
	name: 'BaseComponent',
	computed: {
		$app() {
			return app;
		},
		$route() {
			return app.store.routeContext;
		},
		$store() {
			return app.store;
		},
		$services() {
			return app.store.services;
		},
	},
});
