import '@/dev.content';
import app from '@/app';
import '@/lang/index';
import '@/utils/fv.custom';
import '@/utils/directives';
import '@/utils/filters';
import '@/oweb.custom';
import '@/oweb.components';
import '@/editor';
import '@/uicn.widgets';
import '@/pages';
import '@/jquery.custom';
import launcher from '@/utils/launcher';
import {loadScript} from 'oweb';

const src = app.configs.get('OW_APP_UPDATER_SCRIPT_SRC') as string;

if (src) {
	loadScript(src, undefined, undefined, true);
}

launcher(app).launch();
