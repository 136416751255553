
/**
 * Auto generated file
 *
 * WARNING: please don't edit.
 *
 * Proudly With: gobl v1.5.0
 * Time: 1651227193
 */


import { register } from 'gobl-utils-ts';

import OZUser from './db/OZUser';
import OZAdmin from './db/OZAdmin';
import OZCountry from './db/OZCountry';
import OZFile from './db/OZFile';
import UCChannel from './db/UCChannel';
import UCChannelJoin from './db/UCChannelJoin';
import UCMember from './db/UCMember';
import UCMessage from './db/UCMessage';
import UCMessageLike from './db/UCMessageLike';
import UCPage from './db/UCPage';
import UCNewsletter from './db/UCNewsletter';

register('OZUser', OZUser);
register('OZAdmin', OZAdmin);
register('OZCountry', OZCountry);
register('OZFile', OZFile);
register('UCChannel', UCChannel);
register('UCChannelJoin', UCChannelJoin);
register('UCMember', UCMember);
register('UCMessage', UCMessage);
register('UCMessageLike', UCMessageLike);
register('UCPage', UCPage);
register('UCNewsletter', UCNewsletter);


export {
OZUser,
OZAdmin,
OZCountry,
OZFile,
UCChannel,
UCChannelJoin,
UCMember,
UCMessage,
UCMessageLike,
UCPage,
UCNewsletter,

};
