import app from '@/app';
import {id, OFileQuality, OI18nData, OI18nPluralize, OWebDate} from 'oweb';
import Vue from 'vue';
import {OZUser, UCChannel, UCPage} from '@/gobl';
import OWebTelInput from 'oweb-tel-input';

export const vuePlugin = function () {
	Vue.mixin({
		methods: helpers,
	});
};

const defaultHelpers = {
	i18n(
		key: string,
		data: OI18nData = {},
		pluralize?: OI18nPluralize,
		lang?: string
	) {
		return app.i18n.toHuman(key, data, pluralize, lang);
	},
	ow_notify_offline() {
		!window.navigator.onLine &&
		app.view.dialog({
			type: 'error',
			text: 'UX_LANG_YOU_ARE_OFFLINE',
		});
	},
	ow_file_url(file: string, quality: OFileQuality = 0, def?: string) {
		let parts = file.split('_'),
			url = app.url.get('OZ_SERVER_GET_FILE_URI'),
			o = '0',
			file_id = o,
			file_key = o;

		if (parts.length === 2) {
			file_id = parts[0];
			file_key = parts[1];
		}

		if (def && (file_id === o || file_key === o)) {
			return def;
		}

		let data: any = {
			'{oz_file_id}': file_id,
			'{oz_file_key}': file_key,
			'{oz_file_quality}': quality,
		};

		Object.keys(data).forEach(function (key) {
			url = url.replace(key, data[key]);
		});

		return url;
	},

	ow_local_time(time: string | number) {
		let offset = new Date().getTimezoneOffset() * 60;

		if (typeof time === 'string') {
			time = parseInt(time);
		}

		return (time + offset) * 1000;
	},

	ow_route_link(path: string): string {
		return app.router.pathToURL(path).href;
	},

	ow_loop_key(value: any) {
		const key = '__ow_key_id',
			type = typeof value;

		if (type === 'object' || type === 'function') {
			return key in value ? value[key] : (value[key] = id());
		}

		return id();
	},

	ow_browse_to(route: string) {
		app.router.browseTo(route);
	},

	ow_user_pic(user: OZUser, quality: 0 | 1 | 2 | 3 = 0) {
		let pic = USER_PIC,
			gender = (user.gender || '').toLowerCase();

		if (gender === 'male') {
			pic = USER_MALE;
		} else if (gender === 'female') {
			pic = USER_FEMALE;
		}
		return this.ow_file_url(user.getPicid(), quality, pic);
	},

	ow_custom_data(data: string, def: any = {}) {
		let parsed: any;
		try {
			parsed = JSON.parse(data);
		} catch (e) {
		}

		return parsed || def;
	},

	ow_format_phone(phone: string) {
		phone = phone && String(phone).trim();

		return phone
			? new OWebTelInput({phoneNumber: phone}).getInput(true)
			: phone;
	},

	ow_format_amount(
		amount: number | string,
		currency: string = 'XOF',
		local: string = 'fr-FR'
	) {
		return Number(amount).toLocaleString(local, {
			style: 'currency',
			currency: currency,
		});
	},

	ow_format_date(
		timestamp: number,
		format: string = 'D m Y, h:mm a', inSeconds: boolean = true, isLangKey: boolean = false
	) {
		return new OWebDate(app, inSeconds ? timestamp * 1000 : timestamp).format(format, isLangKey);
	},

	ow_format_date_since(timestamp: number, inSeconds: boolean = true) {
		return new OWebDate(app, inSeconds ? timestamp * 1000 : timestamp).fromNow();
	},

	ow_format_date_input(
		timestamp: number,
		format: string = 'Y-m-dThh:mm', inSeconds: boolean = true, isLangKey: boolean = false
	) {
		return new OWebDate(app, inSeconds ? timestamp * 1000 : timestamp).format(format, isLangKey);
	},

	ow_short_text(text: string, len?: number, ellipsis?: string) {
		len = len || 100;
		ellipsis = text.length > len ? ellipsis || '...' : '';
		text = text.substr(0, len);
		return text.length ? text + ellipsis : '';
	},

	ow_line_to_br(text: string) {
		return (text || '').replace(/\r\n?|\n/g, '<br/>');
	},

	ow_go_back() {
		app.router.goBack();
	},

	ow_can_go_back() {
		return window.history.length > 1;
	},

	ow_uuid() {
		return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(
			/[018]/g,
			(c: any) => {
				return (
					c ^
					(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
				).toString(16);
			}
		);
	}
};

const customHelpers = {
	uc_page_image_url(page: UCPage) {
		return page.image ? helpers.ow_file_url(page.image, 2) : LOGO;
	},
	uc_channel_pic(channel: UCChannel, quality: 0 | 1 | 2 | 3 = 0) {
		return defaultHelpers.ow_file_url(channel.getImage(), quality, CHANNEL_PIC);
	},
	uc_gen_password(minLength: number = 6, maxLength: number = 16) {
		const chars =
			'abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZcv123456789-=_$@*&!#';
		minLength = Math.max(minLength, 6);
		maxLength = Math.max(Math.min(maxLength, 16), minLength);

		const randomNumber = () => Math.floor(Math.random() * 99999),
			n = randomNumber(),
			len = Math.max(minLength, n % (maxLength + 1));
		let pass = '';

		for (let i = 0; i < len; i++) {
			pass += chars[randomNumber() % chars.length];
		}

		return pass;
	},
};
const helpers = {...defaultHelpers, ...customHelpers};

const USER_PIC = require('@/assets/images/user-pic.png');
const USER_MALE = require('@/assets/images/user-male.png');
const USER_FEMALE = require('@/assets/images/user-female.png');

const CHANNEL_PIC = require('@/assets/images/user-pic.png');
const LOGO = require('@/assets/images/icon.png');
export default helpers;
