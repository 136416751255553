
import {wm} from '../utils';
import BaseComponent from '@/components/BaseComponent.vue';
import WEditorWidgetOptions from '@/editor/components/WEditorWidgetOptions.vue';

export default BaseComponent.extend({
	name: 'WEditorForm',
	components: {WEditorWidgetOptions},
	computed: {
		activeWidgetLabel(): string {
			let active = this.$store.editorRoot.activeWidget;
			return (active && wm.get(active.name).label) || '';
		},
		activeWidgetFormOptions: function () {
			if (this.$store.editorRoot.activeWidget) {
				const wgt = wm.get(this.$store.editorRoot.activeWidget.name);

				return wgt.options.props;
			}

			return undefined;
		},
	},
});
