
/**
 * Auto generated file,
 *
 * INFO: you are free to edit it,
 * but make sure to know what you are doing.
 *
 * Proudly With: gobl v1.5.0
 * Time: 1650839726
 */

import { getEntityCache, GoblEntityData } from "gobl-utils-ts";
import UCMemberBase from "./base/UCMemberBase";

export default class UCMember extends UCMemberBase {
	constructor(data?: GoblEntityData) {
		super(data, "UCMember", UCMember.PREFIX, UCMember.COLUMNS);
	}

	public static fromCache(cacheKey: string): UCMember | undefined {
		const cache: any = getEntityCache("UCMember");
		return cache && cache[cacheKey];
	}

	//====================================================
	//=	Your custom implementation goes here
	//====================================================
}
