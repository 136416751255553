
import WWidgetBaseWithChildren from '@/editor/components/widgets/WWidgetBaseWithChildren.vue';
import WEditorWidgetView from '@/editor/components/WEditorWidgetView.vue';

export default WWidgetBaseWithChildren.extend({
	name: 'WWidgetGrid',
	props: ['rowStyle', 'colStyle', 'countMd', 'countSm', 'countLg'],
	components: {WEditorWidgetView},
	computed: {
		classList: function () {
			return [
				'col-' + parseInt(String(12 / this.countSm)),
				'col-md-' + parseInt(String(12 / this.countMd)),
				'col-lg-' + parseInt(String(12 / this.countLg)),
			];
		},
	},
});
