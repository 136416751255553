import {assign, OWebApp} from 'oweb';
import Vue from 'vue';
import {getUniqueId, toUrl, widgetAcceptChildren, wm} from '@/editor/utils';
import {UCPage} from "@/gobl";

const emptyPage = {options: {}, data: []};

class EditorRoot {
	editorFiles: TFile[] = (window.__editorInject.editor_files || []);
	pageId: string | null = null;
	pageContentInitial = '';
	pageOptions: TPageContent['options'] = {};
	rootCollections: TPageContent['data'] = [];
	isViewMode = true;
	activeWidget: TPageWidgetEntry | null = null;
	hoveredWidget: TPageWidgetEntry | null = null;
	childrenReceiver: TPageWidgetEntry | null = null;
	showOptionsForm: boolean = false;
	editorClosed: boolean = true;
	saveCount = 1;

	constructor(protected _appContext: OWebApp) {
	}

	get widgets() {
		return wm.getAll();
	}

	setEditorContentFromPage(page: UCPage) {
		this.closeEditor();
		let pageContent: TPageContent = emptyPage;
		try {
			let p = JSON.parse(page.content);
			pageContent = p ?? emptyPage;
		} catch (e) {
		}

		window.document.title = page.title;
		this._appContext.i18n.setLang(page.lang);

		this.pageId = page.id;
		this.pageOptions = pageContent.options;
		this.rootCollections = pageContent.data;
		this.pageContentInitial = this.serializePageContent();
	}

	setEditorContentFromGlobal() {
		this.closeEditor();
		let pageContent = assign(emptyPage, window.__editorInject.page_target_content || {});
		this.pageId = window.__editorInject.page_target_id;
		this.pageOptions = pageContent.options;
		this.rootCollections = pageContent.data;
		this.pageContentInitial = this.serializePageContent();
	}

	editorMode() {
		this.isViewMode = false;
	}

	websiteMode() {
		this.isViewMode = true;
	}

	setOption(key: string, value: any) {
		Vue.set(this.pageOptions, key, value);
	}

	setHoveredWgt(wgt: TPageWidgetEntry) {
		this.hoveredWidget = wgt;
	}

	isHoveredWgt(wgt: TPageWidgetEntry) {
		return this.hoveredWidget === wgt;
	}

	openEditor() {
		this.editorClosed = false;
	}

	closeEditor() {
		this.editorClosed = true;
	}

	toggleEditor() {
		if (this.editorClosed) {
			this.openEditor();
		} else {
			this.closeEditor();
		}
	}

	hideEditorForm() {
		this.showOptionsForm = !this.showOptionsForm;
	}

	setChildrenReceiver(wgt: TPageWidgetEntry) {
		if (widgetAcceptChildren(wgt)) {
			this.childrenReceiver = wgt;
			this.clearActiveWidget();
			this.openEditor();
		}
	}

	addWidgetToChildren(wgt: TWidget) {
		const item: TPageWidgetEntry = {
			id: 'wgt-v-' + getUniqueId(),
			name: wgt.name,
			data: wm.widgetDefaultOptions(wgt),
		};

		if (widgetAcceptChildren(wgt)) {
			item.children = [];
		}

		if (this.childrenReceiver && this.childrenReceiver.children) {
			this.childrenReceiver.children.push(item);
			this.childrenReceiver = null;
		} else {
			this.rootCollections.push(item);
		}

		this.setActiveWidget(item);
		Vue.nextTick(function () {
			const el = $('#wgt-view-' + item.id).get(0);
			if (el && typeof el.scrollIntoView === 'function') {
				el.scrollIntoView();
			}
		});
	}

	setActiveWidget(wgt: TPageWidgetEntry) {
		this.activeWidget = wgt;
		this.showOptionsForm = true;
		this.openEditor();
	}

	clearActiveWidget() {
		this.activeWidget = null;
		this.showOptionsForm = false;
	}

	serializePageContent() {
		return JSON.stringify({
			options: this.pageOptions,
			data: this.rootCollections,
		});
	}

	save() {
		const success = () => {
			this.pageContentInitial = this.serializePageContent();
			this.saveCount += 1;
			alert('Sauvegarde réussie!');
		};

		if (this.pageId) {
			fetch(toUrl('/editor/save'), {
				method: 'POST',
				body: JSON.stringify({
					id: this.pageId,
					content: this.serializePageContent(),
				}),
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (result) {
					if (result.error) {
						alert(result.msg);
					} else {
						success();
					}
				})
				.catch(function () {
					alert("Erreur lors de la tentative d'enrégistrement.");
				});
		} else {
			success();
		}
	}
}

const customStore = function store(this: OWebApp) {
	return {
		editorRoot: new EditorRoot(this),
		webConfigs: window.__editorInject.web_configs,
	};
};

export default customStore;
