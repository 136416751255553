import {OWebApp, OWebServiceStore} from 'oweb';
import {UCPage} from '@/gobl';

export default class PagesStore extends OWebServiceStore<UCPage> {
	PAGE_TYPE_CUSTOM = 'PAGE_TYPE_CUSTOM';

	constructor(app: OWebApp) {
		super(app, UCPage, 'pages');
	}
}
