
import TTypeBase from '@/editor/components/types/TTypeBase.vue';
import {uuid} from "oweb";

export default TTypeBase.extend({
	name: 'TTypeBool',
	data() {
		return {id: uuid()}
	}
});
