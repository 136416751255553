
import WEditor from '@/editor/components/WEditor.vue';
import WEditorPreview from '@/editor/components/WEditorPreview.vue';
import app from './app';
import BaseComponent from './components/BaseComponent.vue';

export default BaseComponent.extend({
	name: 'App',
	components: {
		WEditor,
		WEditorPreview,
	},
	data() {
		return app.store;
	},
	computed: {
		classes() {
			return {
				'with-editor': !this.$store.editorRoot.isViewMode,
				'editor-opened': !this.$store.editorRoot.editorClosed,
				'disable-scroll': this.$store.splash || this.$store.frozen
			};
		}
	},
});
