
/**
 * Auto generated file
 *
 * WARNING: please don't edit.
 *
 * Proudly With: gobl v1.5.0
 * Time: 1651227193
 */

import {
	GoblEntity,
	_bool,
	_int,
	_string,
} from 'gobl-utils-ts';

export default abstract class UCChannelBase extends GoblEntity {
	public static readonly PREFIX: string = 'channel';
	public static readonly COLUMNS: string[] = [
	'channel_id',
	'channel_added_by',
	'channel_title',
	'channel_short_description',
	'channel_description',
	'channel_image',
	'channel_type',
	'channel_custom_data',
	'channel_create_time',
	'channel_update_time',
	'channel_valid',

	];
	public static readonly COL_ID: string = 'channel_id';
	public static readonly COL_ADDED_BY: string = 'channel_added_by';
	public static readonly COL_TITLE: string = 'channel_title';
	public static readonly COL_SHORT_DESCRIPTION: string = 'channel_short_description';
	public static readonly COL_DESCRIPTION: string = 'channel_description';
	public static readonly COL_IMAGE: string = 'channel_image';
	public static readonly COL_TYPE: string = 'channel_type';
	public static readonly COL_CUSTOM_DATA: string = 'channel_custom_data';
	public static readonly COL_CREATE_TIME: string = 'channel_create_time';
	public static readonly COL_UPDATE_TIME: string = 'channel_update_time';
	public static readonly COL_VALID: string = 'channel_valid';

	public singlePKValue() {
		return this.id;
	}
	public identifierColumns() {
		return [ UCChannelBase.COL_ID ];
	}
	get id() { return _string(this._data[UCChannelBase.COL_ID]); }
	set id(nVal: any) { this._set(UCChannelBase.COL_ID, _string(nVal)); }
	public getId() { return this.id; }
	public setId(nVal: any): this { this.id = nVal; return this; }

	get added_by() { return _string(this._data[UCChannelBase.COL_ADDED_BY]); }
	set added_by(nVal: any) { this._set(UCChannelBase.COL_ADDED_BY, _string(nVal)); }
	public getAddedBy() { return this.added_by; }
	public setAddedBy(nVal: any): this { this.added_by = nVal; return this; }

	get title() { return _string(this._data[UCChannelBase.COL_TITLE]); }
	set title(nVal: any) { this._set(UCChannelBase.COL_TITLE, _string(nVal)); }
	public getTitle() { return this.title; }
	public setTitle(nVal: any): this { this.title = nVal; return this; }

	get short_description() { return _string(this._data[UCChannelBase.COL_SHORT_DESCRIPTION]); }
	set short_description(nVal: any) { this._set(UCChannelBase.COL_SHORT_DESCRIPTION, _string(nVal)); }
	public getShortDescription() { return this.short_description; }
	public setShortDescription(nVal: any): this { this.short_description = nVal; return this; }

	get description() { return _string(this._data[UCChannelBase.COL_DESCRIPTION]); }
	set description(nVal: any) { this._set(UCChannelBase.COL_DESCRIPTION, _string(nVal)); }
	public getDescription() { return this.description; }
	public setDescription(nVal: any): this { this.description = nVal; return this; }

	get image() { return _string(this._data[UCChannelBase.COL_IMAGE]); }
	set image(nVal: any) { this._set(UCChannelBase.COL_IMAGE, _string(nVal)); }
	public getImage() { return this.image; }
	public setImage(nVal: any): this { this.image = nVal; return this; }

	get type() { return _string(this._data[UCChannelBase.COL_TYPE]); }
	set type(nVal: any) { this._set(UCChannelBase.COL_TYPE, _string(nVal)); }
	public getType() { return this.type; }
	public setType(nVal: any): this { this.type = nVal; return this; }

	get custom_data() { return _string(this._data[UCChannelBase.COL_CUSTOM_DATA]); }
	set custom_data(nVal: any) { this._set(UCChannelBase.COL_CUSTOM_DATA, _string(nVal)); }
	public getCustomData() { return this.custom_data; }
	public setCustomData(nVal: any): this { this.custom_data = nVal; return this; }

	get create_time() { return _string(this._data[UCChannelBase.COL_CREATE_TIME]); }
	set create_time(nVal: any) { this._set(UCChannelBase.COL_CREATE_TIME, _string(nVal)); }
	public getCreateTime() { return this.create_time; }
	public setCreateTime(nVal: any): this { this.create_time = nVal; return this; }

	get update_time() { return _string(this._data[UCChannelBase.COL_UPDATE_TIME]); }
	set update_time(nVal: any) { this._set(UCChannelBase.COL_UPDATE_TIME, _string(nVal)); }
	public getUpdateTime() { return this.update_time; }
	public setUpdateTime(nVal: any): this { this.update_time = nVal; return this; }

	get valid() { return _bool(this._data[UCChannelBase.COL_VALID]); }
	set valid(nVal: any) { this._set(UCChannelBase.COL_VALID, _bool(nVal)); }
	public getValid() { return this.valid; }
	public setValid(nVal: any): this { this.valid = nVal; return this; }


}
