
import TTypeBase from '@/editor/components/types/TTypeBase.vue';
import WEditorImageSelector from '@/editor/components/WEditorImageSelector.vue';

export default TTypeBase.extend({
	name: 'TTypeImage',
	components: {WEditorImageSelector},
	data: function () {
		return {
			showSelector: false,
		};
	},
	methods: {
		onSelected: function (list: TFileSelected[]) {
			let item = list[0];
			this.m_value.src = item.src;
			this.m_value.label = item.label || this.m_value.label;

			this.showSelector = false;
		},
		onImageLoaded(event: Event) {
			const image = event.target as HTMLImageElement;
			this.m_value.width = image.naturalWidth;
			this.m_value.height = image.naturalHeight;
		},
		onCanceled: function () {
			this.showSelector = false;
		},
		chooseImage: function () {
			this.showSelector = true;
		},
	},
});
