import {typesWidgets} from '@/editor/utils';
import TTypeNumber from '@/editor/components/types/TTypeNumber.vue';
import TTypeImage from '@/editor/components/types/TTypeImage.vue';
import TTypeSelect from '@/editor/components/types/TTypeSelect.vue';
import TTypeText from '@/editor/components/types/TTypeText.vue';
import TTypeColor from '@/editor/components/types/TTypeColor.vue';
import TTypeIconFont from '@/editor/components/types/TTypeIconFont.vue';
import TTypeTextarea from '@/editor/components/types/TTypeTextarea.vue';
import TTypeUrl from '@/editor/components/types/TTypeUrl.vue';
import TTypeList from '@/editor/components/types/TTypeList.vue';
import TTypeBool from "@/editor/components/types/TTypeBool.vue";

//=========================================================================
typesWidgets.add({
	name: 'number',
	component: TTypeNumber,
	options: {
		label: 'Nombre',
		default: 0,
	},
});

//=========================================================================
typesWidgets.add({
	name: 'bool',
	component: TTypeBool,
	options: {
		label: 'Checkbox',
		yes: 'Yes',
		no: 'No',
		default: false,
	},
});

//=========================================================================
typesWidgets.add({
	name: 'select',
	component: TTypeSelect,
	options: {
		label: 'Sélection',
		default: '',
	},
});

//=========================================================================
typesWidgets.add({
	name: 'text',
	component: TTypeText,
	options: {
		label: 'Texte',
		default: '',
	},
});
//=========================================================================
typesWidgets.add({
	name: 'color',
	component: TTypeColor,
	options: {
		label: 'Couleur',
		default: '',
	},
});
//=========================================================================
typesWidgets.add({
	name: 'icon-font',
	component: TTypeIconFont,
	options: {
		label: 'Icon Font',
		default: 'fab fa-facebook',
	},
});

//=========================================================================
typesWidgets.add({
	name: 'textarea',
	component: TTypeTextarea,
	options: {
		label: 'Long Texte',
		default: '',
	},
});

//=========================================================================
typesWidgets.add({
	name: 'url',
	component: TTypeUrl,
	options: {
		label: 'Lien',
		default: {
			label: 'Lien',
			url: '#',
		},
	},
});

//=========================================================================
typesWidgets.add({
	name: 'image',
	component: TTypeImage,
	options: {
		label: 'Image',
		default: {
			src: require('@/assets/images/cadre.png'),
			label: 'Image',
			width: 278,
			height: 188,
		},
	},
});

//=========================================================================
typesWidgets.add({
	name: 'list',
	component: TTypeList,
	options: {
		label: 'List',
		default: [],
	},
});
