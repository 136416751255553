export const sliderOptions = {
	type: 'list',
	label: 'Slider',
	default: [],
	typeProps: {
		min: 1,
		max: Infinity,
		itemName: 'Slide',
		itemProps: {
			slideType: {
				type: 'select',
				label: 'Style de slide',
				typeProps: {
					options: [
						{
							label: 'Default',
							value: 'a',
						},
						/*{
							label: 'Custom',
							value: 'b',
						},
						{
							label: 'Centered Button',
							value: 'c',
						},*/
					],
				},
				default: 'a',
			},
			image: {
				type: 'image',
				label: 'Image 1600x900',
				default: {src: require('@/assets/images/uicn_kilimadjaro_1600x900.png')},
			},
			link: {type: 'url', default: {url: '#', label: 'en savoir +'}},
			overlyBg: {
				type: 'color',
				default: '#4CAF50',
				label: 'Background',
			},
			title: {
				type: 'textarea',
				label: 'Titre',
				default: 'Ceci est un titre',
			},
			subtitle: {
				type: 'textarea',
				label: 'Sous Titre',
				default: 'Une courte description',
			},
			titleSize: {
				type: "number",
				label: "Taille du titre (en REM)",
				default: 3,
				typeProps: {
					min: 1.2,
					step: 0.1,
				},
			},
			subtitleSize: {
				type: "number",
				label: "Taille du sous-titre (en REM)",
				default: 2,
				typeProps: {
					min: 1.2,
					step: 0.1,
				},
			},
			titleColor: {
				type: 'color',
				default: '#FFFFFF',
				label: 'Couleur du titre',
			},
			subtitleColor: {
				type: 'color',
				default: '#FFFFFF',
				label: 'Couleur du sous-titre',
			},
			btnBg: {
				type: 'color',
				default: '#FFFFFF',
				label: 'Couleur de fond du Btn',
			},
			btnColor: {
				type: 'color',
				default: '#333333',
				label: 'Couleur du texte du Btn',
			},
		},
	},
};