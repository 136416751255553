import Vue from 'vue';
import '@/oweb.boot';
import App from '@/App.vue';
import app from '@/app';
import {vuePlugin} from '@/utils/helpers';

Vue.use(vuePlugin);

Object.freeze(app);

Vue.config.productionTip = false;

new Vue({
	render: (h) => h(App),
}).$mount('#app');
