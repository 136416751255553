import {
	logger,
	OPage,
	ORouteStateObject,
	ORouteTarget,
	OWebApp,
	OWebPager,
	OWebTNet,
} from 'oweb';
import { default as appConfigs } from '@/settings/app.configs';
import { default as userConfigs } from '@/settings/user.configs';
import { default as appUrlList } from '@/settings/app.urls';
import helpers from '@/utils/helpers';
import storeBundle from '@/oweb.bundles';
import Vue from 'vue';
import User from '@/oweb.user';
import { OZUser } from '@/gobl';

if (process.env.NODE_ENV === 'production') {
	Vue.config.errorHandler = function (...args) {
		// this helps debug vue errors in production
		(window as any).__owebVueError = args;
	};

	logger.off();
}

export interface CustomPage extends OPage {
	component: TComponent;
}

const app = OWebApp.create({
	name: appConfigs['OW_APP_NAME'],
	appConfigs,
	userConfigs,
	urls: appUrlList,
	store: storeBundle,
	user: function () {
		return new User<OZUser>(this);
	},
	pager: function () {
		return new OWebPager<CustomPage>(this);
	},
});

app
	.onReady(function () {
		// TODO use entry point for next page after login
		let entryPoint = location.href;
		let requireLoginOnStartup = false;
		logger.log('[App] entry point ->', entryPoint);

		if (/login/.test(entryPoint)) {
			// prevent next to be login if the entry point is already the login page
			entryPoint = this.router.pathToURL('/').href;
		}

		const ctx = this,
			tNet = new OWebTNet(this),
			next = function (path?: string, state?: ORouteStateObject) {
				ctx.store.ready = true;
				ctx.store.splash = false;
				ctx.router.start(true, path, state);
			};

		tNet
			.onReady((state: string) => {
				switch (state) {
					case OWebTNet.STATE_VERIFIED_USER:
						next();
						break;
					case OWebTNet.STATE_UNKNOWN:
					case OWebTNet.STATE_NO_USER:
					case OWebTNet.STATE_OFFLINE_USER:
						app.user.setSessionExpire(0);
						helpers.ow_notify_offline();
						requireLoginOnStartup
							? next('/login', {
									next: entryPoint,
							  })
							: next();
						break;
					case OWebTNet.STATE_SIGN_UP_PROCESS:
						next('/register');
						break;
				}
			})
			.check()
			.catch(function () {
				app.user.setSessionExpire(0);
				helpers.ow_notify_offline();
				next('/login', {
					next: entryPoint,
				});
			});
	})
	.onShowHomePage(function (options: ORouteStateObject) {
		this.router.browseTo('/', options, false, true);
	})
	.onShowLoginPage(function (options: ORouteStateObject) {
		this.router.browseTo(
			'/login',
			{
				next: location.href,
				...options,
			},
			false,
			true
		);
	})
	.onShowRegistrationPage(function (options: ORouteStateObject) {
		this.router.browseTo('/register', options, false, true);
	})
	.onPageNotFound(function (target: ORouteTarget) {
		this.router.browseTo('/');
	});

(window as any).app = app;
export default app;
