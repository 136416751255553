import {OI18nDefinition} from 'oweb';

export default {
	OO_LANG_NEW_VERSION_EXISTS:
		'Une nouvelle version ({new_version}) de {app_name} existe.',
	OO_LANG_APP_UPDATE: 'Mettre à jour',
	OO_LANG_APP_UPDATE_IGNORE: 'Ignorer',
	OO_LANG_LOGIN: 'Se connecter',
	OO_LANG_LOGOUT: 'Se déconnecter',
	OO_LANG_PASSWORD: 'Mot de passe',
	OO_LANG_CONFIRM_PASSWORD: 'Confirmer le mot de passe',
	OO_LANG_CURRENT_PASSWORD: 'Mot de passe actuel',
	OO_LANG_NEW_PASSWORD: 'Nouveau mot de passe',
	OO_LANG_CONFIRM_NEW_PASSWORD: 'Confirmer le nouveau mot de passe',
	OO_LANG_PHONE: 'Numéro',
	OO_LANG_EMAIL: 'E-mail',
	OO_LANG_VALIDATE_BTN: 'Valider',
	OO_LANG_CHECK_BTN: 'Vérifier',
	OO_LANG_VERIFICATION_CODE: 'Code de vérification',
	OO_LANG_ENTER_CODE: 'Entrez le code',
	OO_LANG_MALE: 'Masculin',
	OO_LANG_FEMALE: 'Féminin',
	OO_LANG_SEX: 'Sexe',
	OO_LANG_USER_NAME: 'Nom et Prénoms',
	OO_LANG_BIRTH_DATE: 'Date de naissance',
	OO_LANG_CONNECT: 'Se connecter',
	OO_LANG_REGISTER: "S'inscrire",
	OO_LANG_ACCOUNT_RECOVERY: 'Récupération du compte',
	OO_LANG_DO_ACCOUNT_RECOVERY: 'Récupérer mon compte',
	OO_LANG_DO_YOU_HAVE_ACCOUNT: 'Avez-vous déjà un compte?',
	OO_LANG_DO_NOT_HAVE_ACCOUNT: "Vous n'avez pas de compte?",
	OO_LANG_PASSWORD_FORGOTTEN: 'Mot de passe oublier?',
	OO_LANG_ACCEPT_CGU:
		'J\'accepte les <a class="ux-link" href="/cgu.pdf" target="_blank"> CGU </a>',

	OO_LANG_PASS_EDIT: 'Changer le mot de passe',
	OO_LANG_EDIT: 'Modifier',
	OO_LANG_DELETE: 'Supprimer',

	// =================================================================================
	UX_LOGIN_FORM_TITLE: 'Connexion',
	UX_SIGNUP_FORM_TITLE: 'Inscription',

	UX_LANG_USERS: 'Utilisateurs',

	UX_LANG_FORUM: 'Accéder au forum',
	UX_LANG_FORUM_CHANNELS: 'Discussions',

	UX_CHANNEL_ADD_FORM_TITLE: 'Nouveau sujet de discussion',
	UX_CHANNEL_TITLE: 'Titre',
	UX_CHANNEL_SHORT_DESCRIPTION: 'Courte description',
	UX_CHANNEL_DESCRIPTION: 'Description',
	UX_CHANNEL_TYPE: 'Visibilité',
	UX_CHANNEL_TYPE_PUBLIC: 'Public',
	UX_CHANNEL_TYPE_PRIVATE: 'Privée',
	UX_CHANNEL_ADDED: 'Le sujet de discussion "{title}" a bien été ajouté.',
	UX_CHANNEL_LIST_EMPTY:
		'Vous ne participez actuellement à aucune discussion...',
	UX_CHANNEL_OWNED_LIST_EMPTY:
		"Vous n'avez actuellement aucune discussion créée...",
	UX_CHANNEL_SEARCH_LIST_EMPTY:
		'Aucune discussion ne correspond à votre terme de recherche...',
	UX_CHANNEL_SEARCH_PLACEHOLDER: 'Rechercher discussion...',
	UX_CHANNEL_NO_OPEN_CHANNEL: 'Aucune discussion ouverte...',
	UX_CHANNEL_MESSAGES_LIST_EMPTY: 'Aucun message...',

	UX_NEWSLETTER_SUBSCRIBE: "S'inscrire à la newsletter",
	UX_SEARCH_PLACEHOLDER: 'Rechercher...',

	UX_USER_ACCOUNT_CREATED_PLEASE_CHECK_YOUR_MAILBOX:
		"Votre compte a été créé avec succes. Veuillez vérifier votre boîte mail pour les informations d'accès.",
	UX_USER_PASS_PROMPT:
		'{name} peut se connecter en utilisant {email} et le mot de passe ci-dessous.',

	UX_USER_SHOULD_ACCEPT_CGU: 'Vous devez accepter les CGU.',
	UX_LANG_USER_INFO_EDIT: 'Modifier',

	UX_LANG_YOU_CAN_JOIN_PRIVATE_CHANNEL_ONLY_BY_INVITE:
		"Vous n'êtes pas autorisé à rejoindre cette discussion.",

	UX_LANG_CHANNEL_MEMBER_ADDED: 'Le membre a bien été ajouté.',

	UX_ADD: 'Ajouter',
	UX_SAVE: 'Enrégistrer',

	UX_LANG_IMAGE: 'Image',
	UX_LANG_ADD_DATE: "Date d'ajout",
	UX_LANG_TYPE: 'Type',
	UX_LANG_DASHBOARD: 'Dashboard',

	UX_LANG_SELECT_CHOICE: 'Sélectionné',
	UX_LANG_PREVIEW: 'Précédent',
	UX_LANG_NEXT: 'Suivant',
	UX_LANG_VALIDATE: 'Valider',
	UX_LANG_IGNORE: 'Ignorer',
	UX_LANG_SORT: 'Ordonner',
	UX_LANG_EDIT: 'Modifier',
	UX_LANG_OPEN: 'Voir',
	UX_LANG_CLONE: 'Cloner',
	UX_LANG_OPEN_EDITOR: 'Editeur visuel',
	UX_LANG_EDITOR: 'Editeur visuel',
	UX_LANG_SAVE: 'Enrégistrer',
	UX_LANG_PLACEHOLDER_FOR_SEO: 'Cette description sera utilisée pour le SEO...',
	UX_LANG_LOAD_ERROR: 'Erreur lors du chargement des données...',

	UX_LANG_LANG: 'Langue',
	UX_LANG_FRENCH: 'Français',
	UX_LANG_ENGLISH: 'Englais',

	UX_LANG_ADMIN_PAGE: 'Admin',

	UX_LANG_PAGES: 'Pages',
	UX_LANG_PAGES_NEW: 'Page',
	UX_LANG_PAGES_EDIT: 'Page',
	UX_LANG_PAGES_NEW_TITLE: 'Nouvelle page',
	UX_LANG_PAGES_EDIT_TITLE: "Modification d'une page",
	UX_LANG_PAGES_LIST_TITLE: 'Liste des pages',
	UX_LANG_PAGE_TITLE: 'Titre de la page',
	UX_LANG_PAGES_ADDED: 'La page "{title}" a bien été ajouté.',
	UX_LANG_PAGES_UPDATED: 'La page "{title}" a bien été modifié.',
	UX_LANG_PAGE_DELETE_CONFIRM: 'Voulez-vous vraiment supprimer cette page ?',
	UX_LANG_PAGE_DELETE_SUCCESS: 'La page a bien été supprimé.',
	UX_LANG_PAGE_DELETE_ERROR: 'Erreur lors de la suppression de la page.',
	UX_LANG_PAGES_LIST_EMPTY: 'La liste des pages est vide.',

	UX_LANG_TAGS: 'Tags',
	UX_LANG_URL: 'Url',
	UX_LANG_VISIBLE: 'Rendre visible',
	UX_LANG_IMPORTANT: 'Très important',
	UX_LANG_YES: 'Oui',
	UX_LANG_NO: 'Non',
	UX_LANG_SHORT_DESCRIPTION: 'Courte description',
	UX_LANG_DESCRIPTION: 'Description',
	UX_LANG_IS_FOR_SIDEBAR: 'Sidebar',

	UX_LANG_NEWSLETTERS: 'Newsletters',
	UX_LANG_NEWSLETTERS_LIST_EMPTY: 'La liste des newsletters est vide.',
	UX_LANG_NEWSLETTERS_LIST_TITLE: 'Abonnés à la Newsletters',

	UX_LANG_CONFIGS_WEB_FR: 'Configs FR',
	UX_LANG_CONFIGS_WEB_EN: 'Configs EN',
	UX_LANG_CONFIGS_SAVED: 'Configurations enrégistrées avec succès.',
	UX_LANG_CONFIGS: 'Paramètres',
	UX_LANG_CONFIGS_EMPTY: 'Erreur de chargement ou aucune configuration.',
	UX_LANG_CONFIGS_MENU_GROUP_ADD: 'Ajouter un groupe',
	UX_LANG_CONFIGS_MOVE_UP: 'Déplacer vers le haut',
	UX_LANG_CONFIGS_MOVE_DOWN: 'Déplacer vers le bas',
	UX_LANG_CONFIGS_TOGGLE: 'Toggle',
	UX_LANG_CONFIGS_ADD_SUB_MENU: 'Ajouter un sous menu',
	UX_LANG_CONFIGS_PROP_TITLE: 'Titre',
	UX_LANG_CONFIGS_PROP_SUB_TITLE: 'Sous-titre',
	UX_LANG_CONFIGS_PROP_URL: 'Url',
	UX_LANG_CONFIGS_PROP_DESCRIPTION: 'Description',

	UX_LANG_FOOTER_EVENTS: 'Evénements',
	UX_LANG_PAGE_TITLE_DEFAULT: 'UICN: Reb-Paco',
	UX_LANG_BLACKLIST: 'Bannir',
	UX_LANG_SETTINGS: 'Configs',

	UX_LANG_FORM_LAST_NAME: 'Nom',
	UX_LANG_FORM_FIRST_NAME: 'Prénom',
	UX_LANG_FORM_NAME: 'Nom et prénoms',
	UX_LANG_FORM_EMAIL: 'E-mail',
	UX_LANG_FORM_COMPANY_NAME: "Nom de l'entreprise",

	UX_LANG_MEMBERSHIP_FORM_TITLE: "Formulaire d'adhésion",

	UX_LANG_MEMBERSHIP_FORM_MEMBERSHIP_TYPE: "Type d'adhésion",
	UX_LANG_MEMBERSHIP_FORM_TYPE_GENERAL_MEMBERSHIP: 'Adhésion générale',
	UX_LANG_MEMBERSHIP_FORM_TYPE_SPONSORSHIP_MEMBERSHIP: 'Adhésion de parrainage',
	UX_LANG_MEMBERSHIP_FORM_TYPE_ASSOCIATE_MEMBERSHIP:
		'Adhésion comme membre associé',

	UX_LANG_MEMBERSHIP_FORM_MEMBERSHIP_MOTIVATION: "Motivation d'adhésion",
	UX_LANG_MEMBERSHIP_FORM_TYPE_BUSINESS_EXAMPLE:
		"Être un exemple pour d'autres entreprises",
	UX_LANG_MEMBERSHIP_FORM_TYPE_CONSERVATION_RELATED_INFORMATION:
		'Pour obtenir et (ou) partager des informations sur la conservation',
	UX_LANG_MEMBERSHIP_FORM_TYPE_INTERNATIONAL_RECOGNITION:
		'Pour avoir une reconnaissance locale ou internationale',
	UX_LANG_MEMBERSHIP_FORM_TYPE_PARTICIPATION_CAPACITY_BUILDING_EVENTS:
		'Pour participer aux évènements liés au renforcement des capacités',
	UX_LANG_MEMBERSHIP_FORM_TYPE_CONTRIBUTION_TO_CONSERVATION_NATURE:
		'Pour contribuer à la conservation de la nature',
	UX_LANG_MEMBERSHIP_FORM_TYPE_PUBLICIZE_SHARE_ACTIVITIES:
		'Faire connaître / partager les activités de conservation',
	UX_LANG_MEMBERSHIP_FORM_TYPE_ESTABLISH_CONTACTS: 'Établir des contacts',
	UX_LANG_MEMBERSHIP_FORM_TYPE_OTHERS: 'Autres',

	UX_LANG_MEMBERSHIP_FORM_MOTIVATION_DESCRIPTION: 'Décrire votre motivation',

	UX_LANG_MEMBERSHIP_FORM_BUTTON: 'Adhérer',

	UX_LANG_NEWSLETTER_FORM_TITLE: 'Inscrivez-vous à notre newsletter',
	UX_LANG_NEWSLETTER_FORM_BUTTON: 'Souscrire',
	UX_LANG_NEWSLETTER_SUCCESS: 'Votre inscription à la newsletter est réussie',
	UX_SHARE_ON_SOCIAL_MEDIA: 'Partager sur {socialMediaName}',
} as OI18nDefinition;
