
import WWidgetBase from '@/editor/components/widgets/WWidgetBase.vue';

export default WWidgetBase.extend({
	name: 'WWidgetVideoPlayer',
	props: {
		videoId: String,
		bgColor: String,
		iframeBorderColor: String
	},
	computed: {
		styles: function () {
			return {
				"--video-player-bg-color": this.bgColor,
				"--video-player-border-color": this.iframeBorderColor
			};
		},
		videoUrl: function () {
			if (this.videoId) {
				if (/^http/.test(String(this.videoId))) {

					return this.videoId;
				}

				return "https://www.youtube.com/embed/"
					+ this.videoId
					+ "?autoplay=1&color=white&loop=false&controls=true&showinfo=0";
			}

			return "";
		}
	}
});
