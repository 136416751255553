
import Vue from 'vue';
import {widgetAcceptChildren, wm} from '../utils';

import BaseComponent from '@/components/BaseComponent.vue';

export default BaseComponent.extend({
	name: 'WEditorWidgetView',
	props: {
		collections: {required: true, type: Array},
		item: {required: true, type: Object},
		noOptions: {required: false, type: Boolean, default: false},
	},
	data: function () {
		return {};
	},
	computed: {
		classList: function () {
			return {
				'show-options-on-hover': this.$store.editorRoot.isHoveredWgt(this.item),
			};
		},
		inCanvasProps() {
			return {
				inCanvas: true,
				inViewMode: this.$store.editorRoot.isViewMode,
			}
		}
	},
	methods: {
		widgetAcceptChildren,
		onMouseOver: function () {
			this.$store.editorRoot.setHoveredWgt(this.item);
		},
		getWidget: function (name: string) {
			return wm.get(name);
		},
		getWidgetIndex: function (wgt: TPageWidgetEntry) {
			var index = null;
			for (var i = 0; i < this.collections.length; i++) {
				var c = this.collections[i] as TPageWidgetEntry;
				if (c.id === wgt.id) {
					index = i;
					break;
				}
			}

			return index;
		},
		addChildren: function (item: TPageWidgetEntry) {
			this.$store.editorRoot.setChildrenReceiver(item);
		},
		moveWidgetUp: function (wgt: TPageWidgetEntry) {
			var index = this.getWidgetIndex(wgt);

			if (!(index === null) && index > 0) {
				var tmp = this.collections[index - 1];
				Vue.set(this.collections, index - 1, wgt);
				Vue.set(this.collections, index, tmp);
			}
		},
		moveWidgetDown: function (wgt: TPageWidgetEntry) {
			var index = this.getWidgetIndex(wgt);

			if (!(index === null) && index < this.collections.length - 1) {
				var tmp = this.collections[index + 1];
				Vue.set(this.collections, index + 1, wgt);
				Vue.set(this.collections, index, tmp);
			}
		},
		removeWidget: function (wgt: TPageWidgetEntry) {
			var index = this.getWidgetIndex(wgt);
			if (!(index === null)) {
				this.$store.editorRoot.clearActiveWidget();
				this.collections.splice(index, 1);
			}
		},
		canGoUp: function (wgt: TPageWidgetEntry) {
			var index = this.getWidgetIndex(wgt);
			return !(index === null) && index > 0;
		},
		canGoDown: function (wgt: TPageWidgetEntry) {
			var index = this.getWidgetIndex(wgt);
			return !(index === null) && index < this.collections.length - 1;
		},
	},
});
