import {wm} from '@/editor/utils';
import WWidgetGrid from '@/editor/components/widgets/WWidgetGrid.vue';
import WWidgetGridOneThirdTwoThird from '@/editor/components/widgets/WWidgetGridOneThirdTwoThird.vue';
import WWidgetRawHtml from '@/editor/components/widgets/WWidgetRawHtml.vue';
import WWidgetSlider from '@/editor/components/widgets/WWidgetSlider.vue';
import WWidgetGoogleMapUrl from '@/editor/components/widgets/WWidgetGoogleMapUrl.vue';
import WWidgetImage from '@/editor/components/widgets/WWidgetImage.vue';
import WWidgetContainer from '@/editor/components/widgets/WWidgetContainer.vue';
import WWidgetGoogleMap from '@/editor/components/widgets/WWidgetGoogleMap.vue';
import WWidgetRichText from '@/editor/components/widgets/WWidgetRichText.vue';
import WWidgetVideoPlayer from "@/editor/components/widgets/WWidgetVideoPlayer.vue";
import {sliderOptions} from "@/slider.options";

//=========================================================================
wm.add({
	name: 'grid',
	component: WWidgetGrid,
	label: 'Grille',
	options: {
		children: true,
		props: {
			countSm: {
				type: 'select',
				label: 'Nombre par ligne (Smartphone)',
				typeProps: {
					options: [
						{
							label: 'Un',
							value: 1,
						},
						{
							label: 'Deux',
							value: 2,
						},
						{
							label: 'Trois',
							value: 3,
						},
						{
							label: 'Quatre',
							value: 4,
						},
						{
							label: 'Six',
							value: 6,
						},
						{
							label: 'Douze',
							value: 12,
						},
					],
				},
				default: 1,
			},
			countMd: {
				type: 'select',
				label: 'Nombre par ligne (Tablettes)',
				typeProps: {
					options: [
						{
							label: 'Un',
							value: 1,
						},
						{
							label: 'Deux',
							value: 2,
						},
						{
							label: 'Trois',
							value: 3,
						},
						{
							label: 'Quatre',
							value: 4,
						},
						{
							label: 'Six',
							value: 6,
						},
						{
							label: 'Douze',
							value: 12,
						},
					],
				},
				default: 2,
			},
			countLg: {
				type: 'select',
				label: 'Nombre par ligne (PC)',
				typeProps: {
					options: [
						{
							label: 'Un',
							value: 1,
						},
						{
							label: 'Deux',
							value: 2,
						},
						{
							label: 'Trois',
							value: 3,
						},
						{
							label: 'Quatre',
							value: 4,
						},
						{
							label: 'Six',
							value: 6,
						},
						{
							label: 'Douze',
							value: 12,
						},
					],
				},
				default: 4,
			},
			rowStyle: {
				type: 'textarea',
				label: 'Row style',
				default: `padding: 0;
margin: 0px -15px;`,
			},
			colStyle: {
				type: 'textarea',
				label: 'Col style',
				default: `padding: 0px 15px;
margin: 0;`,
			},
		},
	},
});

//=========================================================================
wm.add({
	name: 'grid-one-third-two-third',
	component: WWidgetGridOneThirdTwoThird,
	label: 'Grille 1/3 & 2/3',
	options: {
		children: true,
		props: {
			rowStyle: {
				type: 'textarea',
				label: 'Row style',
				default: `padding: 0;
margin: 0px -15px;`,
			},
			colStyle: {
				type: 'textarea',
				label: 'Col style',
				default: `padding: 0px 15px;
margin: 0;`,
			},
		},
	},
});

//=========================================================================
wm.add({
	name: 'raw-html',
	component: WWidgetRawHtml,
	label: 'Raw HTML',
	options: {
		props: {
			content: {type: 'textarea', default: '', label: 'Raw HTML'},
		},
	},
});

//=========================================================================
wm.add({
	name: "video-player",
	component: WWidgetVideoPlayer,
	label: "Video Player",
	options: {
		props: {
			videoId: {
				type: "text",
				label: "Youtube Video Id or Link",
				default: ""
			},
			bgColor: {
				type: "color",
				default: "#FFFFFF",
				label: "Background Color"
			},
			iframeBorderColor: {
				type: "color",
				default: "#E6E6E6",
				label: "Border Color"
			}
		}
	}
});

//=========================================================================
wm.add({
	name: 'slider',
	component: WWidgetSlider,
	label: 'Slider',
	options: {
		props: {
			slides: sliderOptions
		},
	},
});

//=========================================================================
wm.add({
	name: 'google-map-url',
	component: WWidgetGoogleMapUrl,
	label: 'Google Map Url',
	options: {
		props: {
			embedUrl: {
				type: 'text',
				label: 'Lien Google Map',
				default:
					'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4031868.103619012!2d0.06484612772852213!3d9.300339618256649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1023542b047a5695%3A0xecb277f8cb622ef5!2sBenin!5e0!3m2!1sen!2sbj!4v1601348815665!5m2!1sen!2sbj',
			},
		},
	},
});

//=========================================================================
wm.add({
	name: 'container',
	component: WWidgetContainer,
	label: 'Container',
	options: {
		children: true,
		props: {
			containerBg: {
				type: 'color',
				default: '#F5F5F5',
				label: 'Background',
			},
			containerPadding: {
				type: 'number',
				default: 10,
				typeProps: {
					min: 0,
					step: 1,
				},
				label: 'Padding',
			},
			innerStyle: {
				type: 'textarea',
				default: 'display: block;',
				label: 'Style CSS',
			},
		},
	},
});

//=========================================================================
wm.add({
	name: 'image',
	component: WWidgetImage,
	label: 'Image',
	options: {
		props: {
			image: {
				type: 'image',
				default: {src: require('@/assets/images/uicn-kilimadjaro.png')},
			},
			styleCss: {
				type: 'textarea',
				default: 'display: block;\nmargin: 0 auto;',
				label: 'Style CSS',
			},
		},
	},
});

//=========================================================================
wm.add({
	name: 'google-map',
	component: WWidgetGoogleMap,
	label: 'Google Map',
	options: {
		props: {
			embedId: {type: 'text', default: '1V7_cWL-jbCKK4HbZS41vXwAH6og'},
		},
	},
});

//=========================================================================
wm.add({
	name: 'rich-text',
	label: 'Texte Riche',
	component: WWidgetRichText,
	options: {
		props: {
			content: {
				type: 'textarea',
				typeProps: {
					rows: 12,
				},
				default: `# Titre niveau 1
## Titre niveau 2
### Titre niveau 3
#### Titre niveau 4
##### Titre niveau 5
###### Titre niveau 6

_Texte en italique_

**Texte en gras**

\`Texte important\`

Liste des fruits favorites

- Avocat
- Banane
- Mangue
  - Mûr
  - Non mûr
- Ananas


Il est \`Important\` de noter que ceci n'est que du text avec une \`synthax\` simple



[Ceci est un lien](https://google.com)

[Un autre lien téléphonique](tel:+229 96 00 00 00)
`,
			},
		},
	},
});
