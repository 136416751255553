
/**
 * Auto generated file,
 *
 * INFO: you are free to edit it,
 * but make sure to know what you are doing.
 *
 * Proudly With: gobl v1.5.0
 * Time: 1650839726
 */

import { getEntityCache, GoblEntityData } from "gobl-utils-ts";
import UCChannelBase from "./base/UCChannelBase";

export default class UCChannel extends UCChannelBase {
	constructor(data?: GoblEntityData) {
		super(data, "UCChannel", UCChannel.PREFIX, UCChannel.COLUMNS);
	}

	public static fromCache(cacheKey: string): UCChannel | undefined {
		const cache: any = getEntityCache("UCChannel");
		return cache && cache[cacheKey];
	}

	//====================================================
	//=	Your custom implementation goes here
	//====================================================
}
