/**
 * Auto-generated file, please don't edit
 *
 * O'Web CLI 1.0.0
 *
 * Mon, 28 Mar 2022 10:00:51 GMT
 */

import Vue from 'vue';

Vue.component('w-field', () => import('./components/fields/WField.vue'));
Vue.component(
	'w-field-button',
	() => import('./components/fields/WFieldButton.vue')
);
Vue.component('w-field-cgu', () => import('./components/fields/WFieldCgu.vue'));
Vue.component(
	'w-field-checkbox',
	() => import('./components/fields/WFieldCheckbox.vue')
);
Vue.component(
	'w-field-file',
	() => import('./components/fields/WFieldFile.vue')
);
Vue.component(
	'w-field-hidden',
	() => import('./components/fields/WFieldHidden.vue')
);
Vue.component(
	'w-field-image',
	() => import('./components/fields/WFieldImage.vue')
);
Vue.component(
	'w-field-input',
	() => import('./components/fields/WFieldInput.vue')
);
Vue.component(
	'w-field-phone',
	() => import('./components/fields/WFieldPhone.vue')
);
Vue.component(
	'w-field-radio',
	() => import('./components/fields/WFieldRadio.vue')
);
Vue.component(
	'w-field-range',
	() => import('./components/fields/WFieldRange.vue')
);
Vue.component(
	'w-field-select',
	() => import('./components/fields/WFieldSelect.vue')
);
Vue.component(
	'w-field-select-group',
	() => import('./components/fields/WFieldSelectGroup.vue')
);
Vue.component(
	'w-field-submit',
	() => import('./components/fields/WFieldSubmit.vue')
);
Vue.component(
	'w-field-textarea',
	() => import('./components/fields/WFieldTextarea.vue')
);
Vue.component('w-form', () => import('./components/forms/WForm.vue'));
Vue.component(
	'w-form-custom',
	() => import('./components/forms/WFormCustom.vue')
);

Vue.component('w-captcha', () => import('./components/oweb/WCaptcha.vue'));
Vue.component('w-dialog', () => import('./components/oweb/WDialog.vue'));
Vue.component(
	'w-dialog-view',
	() => import('./components/oweb/WDialogView.vue')
);
Vue.component('w-freezer', () => import('./components/oweb/WFreezer.vue'));
Vue.component('w-loader', () => import('./components/oweb/WLoader.vue'));
Vue.component(
	'w-loader-full',
	() => import('./components/oweb/WLoaderFull.vue')
);
Vue.component('w-main', () => import('./components/oweb/WMain.vue'));
Vue.component('w-nav', () => import('./components/oweb/WNav.vue'));
Vue.component('w-page', () => import('./components/oweb/WPage.vue'));
Vue.component('w-page-error', () => import('./components/oweb/WPageError.vue'));
Vue.component('w-splash', () => import('./components/oweb/WSplash.vue'));
Vue.component(
	'w-user-account-recovery-one',
	() => import('./components/oweb/WUserAccountRecoveryOne.vue')
);
Vue.component(
	'w-user-account-recovery-three',
	() => import('./components/oweb/WUserAccountRecoveryThree.vue')
);
Vue.component(
	'w-user-account-recovery-two',
	() => import('./components/oweb/WUserAccountRecoveryTwo.vue')
);
Vue.component('w-user-edit', () => import('./components/oweb/WUserEdit.vue'));
Vue.component(
	'w-user-edit-pass',
	() => import('./components/oweb/WUserEditPass.vue')
);
Vue.component(
	'w-user-register-one',
	() => import('./components/oweb/WUserRegisterOne.vue')
);
Vue.component(
	'w-user-register-three',
	() => import('./components/oweb/WUserRegisterThree.vue')
);
Vue.component(
	'w-user-register-two',
	() => import('./components/oweb/WUserRegisterTwo.vue')
);
Vue.component('w-user-view', () => import('./components/oweb/WUserView.vue'));
