
import app from '@/app';
import BaseComponent from '@/components/BaseComponent.vue';
import WFreezer from '@/components/oweb/WFreezer.vue';
import {ozFileUrl, toUrl} from '../utils';

type TTabs = 'gallery' | 'uploader';
export default BaseComponent.extend({
	name: 'WEditorImageSelector',
	components: {WFreezer},
	data: function () {
		return {
			gallery: ([] as TFile[]).concat(app.store.editorRoot.editorFiles),
			active: 'gallery' as TTabs,
			loading: false,
			files: [] as File[],
		};
	},
	methods: {
		ozFileUrl,
		closeModal: function () {
			this.$emit('canceled');
		},
		selectImage: function (item: TFile) {
			const selected: TFileSelected[] = [
				{
					label: item.name,
					src: ozFileUrl(item),
				},
			];
			this.$emit('selected', selected);
		},
		setActiveTab: function (tab: TTabs) {
			this.active = tab;
		},
		onChange: function (e: InputEvent) {
			var files = (e.target as HTMLInputElement).files || [];
			for (var i = 0; i < files.length; i++) {
				this.files.push(files[i]);
			}

			var f = this.$refs.form as HTMLFormElement;
			f && f.reset();
		},
		uploadFile: function () {
			var x = this,
				fd = new FormData();

			if (this.files.length) {
				for (var i = 0; i < this.files.length; i++) {
					fd.append('editor_upload[]', this.files[i]);
				}
			} else {
				return alert('Veuillez sélectionner des images...');
			}

			this.loading = true;
			var always = function () {
				x.loading = false;
			};

			fetch(toUrl('/editor/upload'), {
				method: 'POST',
				body: fd,
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (result) {
					if (result.error) {
						alert(result.msg);
					} else {
						x.files = [];
						x.$store.editorRoot.editorFiles = x.gallery = result.data;
					}
					always();
				})
				.catch(function () {
					alert("Erreur lors de l'upload.");
					always();
				});
		},
	},
});
