import {OAppConfigs} from 'oweb';

const NAME = 'UICNBeninWebsite',
	VERSION = '0.1.0',
	DEBUG = process.env.NODE_ENV !== 'production',
	API_URL = DEBUG
		? 'https://www.uicn.com/api'
		: location.origin + '/api',
	ANDROID_ID = 'com.uicn.app',
	UPDATE_SRC = '',
	LOGO_SRC = require('@/assets/images/icon.png');

let w_loc = window.location,
	w_doc = window.document,
	html_reg = /[^/]+\.html$/i,
	app_use_hash = false,
	base_uri = app_use_hash ? w_doc.baseURI || w_loc.origin : w_loc.origin,
	app_url = app_use_hash
		? w_loc.protocol + '//' + w_loc.host + w_loc.pathname.replace(html_reg, '')
		: base_uri.replace(html_reg, '').replace(w_loc.hash, '');

export default {
	OW_APP_NAME: NAME,
	OW_APP_VERSION: VERSION,
	OW_APP_LOCAL_BASE_URL: app_url,
	OW_APP_ROUTER_HASH_MODE: app_use_hash,
	OW_APP_ALLOWED_COUNTRIES: ['bj'],
	OW_APP_LOGO_SRC: LOGO_SRC,
	OW_APP_ANDROID_ID: ANDROID_ID,
	OW_APP_UPDATER_SCRIPT_SRC: UPDATE_SRC,

	OZ_API_KEY_HEADER_NAME: 'x-ozone-api-key',
	OZ_API_ALLOW_REAL_METHOD_HEADER: true,
	OZ_API_REAL_METHOD_HEADER_NAME: 'x-ozone-real-method',
	OZ_API_KEY: '23B8F967-54FABBA5-84DC0678-830F0559',
	OZ_API_BASE_URL: API_URL,

	OZ_CODE_REG: '^[0-9]{6}$',
	OZ_USER_NAME_MIN_LENGTH: 3,
	OZ_USER_NAME_MAX_LENGTH: 60,
	OZ_PASS_MIN_LENGTH: 6,
	OZ_PASS_MAX_LENGTH: 60,
	OZ_USER_MIN_AGE: 12,
	OZ_USER_MAX_AGE: 100,
	OZ_PPIC_MIN_SIZE: 150,
	OZ_USER_ALLOWED_GENDERS: ['Male', 'Female'],

	EDITOR_WEBSITE_BASE_URL: API_URL.replace('api.', 'www.').replace('/api', ''),
} as OAppConfigs;
