import { render, staticRenderFns } from "./WEditorImageSelector.vue?vue&type=template&id=7a272667&scoped=true&"
import script from "./WEditorImageSelector.vue?vue&type=script&lang=ts&"
export * from "./WEditorImageSelector.vue?vue&type=script&lang=ts&"
import style0 from "./WEditorImageSelector.vue?vue&type=style&index=0&id=7a272667&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_vsyllalxesco42bbuzcqgel7xq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a272667",
  null
  
)

export default component.exports