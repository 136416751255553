import { OI18nDefinition } from 'oweb';

export default {
	OO_LANG_NEW_VERSION_EXISTS:
		'A new version ({new_version}) of {app_name} exists.',
	OO_LANG_APP_UPDATE: 'Update',
	OO_LANG_APP_UPDATE_IGNORE: 'Ignore',
	OO_LANG_LOGIN: 'Log in',
	OO_LANG_LOGOUT: 'Sign out',
	OO_LANG_PASSWORD: 'Password',
	OO_LANG_CONFIRM_PASSWORD: 'Confirm password',
	OO_LANG_CURRENT_PASSWORD: 'Current Password',
	OO_LANG_NEW_PASSWORD: 'New Password',
	OO_LANG_CONFIRM_NEW_PASSWORD: 'Confirm the new password',
	OO_LANG_PHONE: 'Phone',
	OO_LANG_EMAIL: 'E-mail',
	OO_LANG_VALIDATE_BTN: 'Validate',
	OO_LANG_CHECK_BTN: 'Check',
	OO_LANG_VERIFICATION_CODE: 'Verification code',
	OO_LANG_ENTER_CODE: 'Enter code',
	OO_LANG_MALE: 'Male',
	OO_LANG_FEMALE: 'Female',
	OO_LANG_SEX: 'Sex',
	OO_LANG_USER_NAME: 'Last name and first names',
	OO_LANG_BIRTH_DATE: 'Date of Birth',
	OO_LANG_CONNECT: 'Log in',
	OO_LANG_REGISTER: 'Register',
	OO_LANG_ACCOUNT_RECOVERY: 'Account Recovery',
	OO_LANG_DO_ACCOUNT_RECOVERY: 'Recover my account',
	OO_LANG_DO_YOU_HAVE_ACCOUNT: 'Do you already have an account?',
	OO_LANG_DO_NOT_HAVE_ACCOUNT: 'You do not have an account?',
	OO_LANG_PASSWORD_FORGOTTEN: 'Forgotten password?',
	OO_LANG_ACCEPT_CGU:
		'I accept the <a class="ux-link" href="/cgu.pdf" target="_blank"> TOS </a>',
	OO_LANG_PASS_EDIT: 'Change password',
	OO_LANG_EDIT: 'Edit',
	OO_LANG_DELETE: 'Delete',
	UX_LOGIN_FORM_TITLE: 'Login',
	UX_SIGNUP_FORM_TITLE: 'Registration',
	UX_LANG_USERS: 'Users',
	UX_LANG_FORUM: 'Access the forum',
	UX_LANG_FORUM_CHANNELS: 'Talks',
	UX_CHANNEL_ADD_FORM_TITLE: 'New discussion topic',
	UX_CHANNEL_TITLE: 'Title',
	UX_CHANNEL_SHORT_DESCRIPTION: 'Short description',
	UX_CHANNEL_DESCRIPTION: 'Description',
	UX_CHANNEL_TYPE: 'Visibility',
	UX_CHANNEL_TYPE_PUBLIC: 'Audience',
	UX_CHANNEL_TYPE_PRIVATE: 'Private',
	UX_CHANNEL_ADDED: 'Channel "{title}" successfully added.',
	UX_CHANNEL_LIST_EMPTY:
		'You are not currently participating in any discussions...',
	UX_CHANNEL_OWNED_LIST_EMPTY: 'You currently have no discussions created...',
	UX_CHANNEL_SEARCH_LIST_EMPTY: 'No channels match your search term...',
	UX_CHANNEL_SEARCH_PLACEHOLDER: 'Search discussion...',
	UX_CHANNEL_NO_OPEN_CHANNEL: 'No open discussion...',
	UX_CHANNEL_MESSAGES_LIST_EMPTY: 'No message...',
	UX_NEWSLETTER_SUBSCRIBE: 'Subscribe to the newsletter',
	UX_SEARCH_PLACEHOLDER: 'Search...',
	UX_USER_ACCOUNT_CREATED_PLEASE_CHECK_YOUR_MAILBOX:
		'Your account has been successfully created. Please check your email for access information.',
	UX_USER_PASS_PROMPT: '{name} can login using {email} and the password below.',
	UX_USER_SHOULD_ACCEPT_CGU: 'You must accept the TOS.',
	UX_LANG_USER_INFO_EDIT: 'Edit',
	UX_LANG_YOU_CAN_JOIN_PRIVATE_CHANNEL_ONLY_BY_INVITE:
		'You are not allowed to join this discussion.',
	UX_LANG_CHANNEL_MEMBER_ADDED: 'The member has been added successfully.',
	UX_ADD: 'Add',
	UX_SAVE: 'Save',
	UX_LANG_IMAGE: 'Image',
	UX_LANG_ADD_DATE: 'Date Added',
	UX_LANG_TYPE: 'Kind',
	UX_LANG_DASHBOARD: 'Dashboard',
	UX_LANG_SELECT_CHOICE: 'Select',
	UX_LANG_PREVIEW: 'Previous',
	UX_LANG_NEXT: 'Next',
	UX_LANG_VALIDATE: 'Validate',
	UX_LANG_IGNORE: 'Ignore',
	UX_LANG_SORT: 'Re-order',
	UX_LANG_EDIT: 'Edit',
	UX_LANG_OPEN: 'See',
	UX_LANG_CLONE: 'Clone',
	UX_LANG_OPEN_EDITOR: 'Visual editor',
	UX_LANG_EDITOR: 'Visual editor',
	UX_LANG_SAVE: 'Save',
	UX_LANG_PLACEHOLDER_FOR_SEO: 'This description will be used for SEO...',
	UX_LANG_LOAD_ERROR: 'Error loading data...',
	UX_LANG_LANG: 'Language',
	UX_LANG_FRENCH: 'French',
	UX_LANG_ENGLISH: 'English',
	UX_LANG_ADMIN_PAGE: 'Admin',
	UX_LANG_PAGES: 'Pages',
	UX_LANG_PAGES_NEW: 'Page',
	UX_LANG_PAGES_EDIT: 'Page',
	UX_LANG_PAGES_NEW_TITLE: 'New page',
	UX_LANG_PAGES_EDIT_TITLE: 'Editing a page',
	UX_LANG_PAGES_LIST_TITLE: 'List of pages',
	UX_LANG_PAGE_TITLE: 'Page title',
	UX_LANG_PAGES_ADDED: 'The page "{title}" has been added.',
	UX_LANG_PAGES_UPDATED: 'The page "{title}" has been modified.',
	UX_LANG_PAGE_DELETE_CONFIRM: 'Are you sure you want to delete this page?',
	UX_LANG_PAGE_DELETE_SUCCESS: 'The page has been successfully deleted.',
	UX_LANG_PAGE_DELETE_ERROR: 'Error while deleting the page.',
	UX_LANG_PAGES_LIST_EMPTY: 'The list of pages is empty.',
	UX_LANG_TAGS: 'Tags',
	UX_LANG_URL: 'URL',
	UX_LANG_VISIBLE: 'Make visible',
	UX_LANG_IMPORTANT: 'Very important',
	UX_LANG_YES: 'Yes',
	UX_LANG_NO: 'No',
	UX_LANG_SHORT_DESCRIPTION: 'Short description',
	UX_LANG_DESCRIPTION: 'Description',
	UX_LANG_IS_FOR_SIDEBAR: 'Sidebar',
	UX_LANG_NEWSLETTERS: 'Newsletters',
	UX_LANG_NEWSLETTERS_LIST_EMPTY: 'The newsletters list is empty.',
	UX_LANG_NEWSLETTERS_LIST_TITLE: 'Newsletter subscribers',
	UX_LANG_CONFIGS_WEB_FR: 'FR Configs',
	UX_LANG_CONFIGS_WEB_EN: 'EN Configs',
	UX_LANG_CONFIGS_SAVED: 'Configurations saved successfully.',
	UX_LANG_CONFIGS: 'Settings',
	UX_LANG_CONFIGS_EMPTY: 'Loading error or no configuration.',
	UX_LANG_CONFIGS_MENU_GROUP_ADD: 'Add a group',
	UX_LANG_CONFIGS_MOVE_UP: 'Move up',
	UX_LANG_CONFIGS_MOVE_DOWN: 'Move down',
	UX_LANG_CONFIGS_TOGGLE: 'Toggle',
	UX_LANG_CONFIGS_ADD_SUB_MENU: 'Add a sub menu',
	UX_LANG_CONFIGS_PROP_TITLE: 'Title',
	UX_LANG_CONFIGS_PROP_SUB_TITLE: 'Subtitle',
	UX_LANG_CONFIGS_PROP_URL: 'URL',
	UX_LANG_CONFIGS_PROP_DESCRIPTION: 'Description',
	UX_LANG_FOOTER_EVENTS: 'Events',
	UX_LANG_PAGE_TITLE_DEFAULT: 'IUCN: Reb-Paco',
	UX_LANG_BLACKLIST: 'Ban',
	UX_LANG_SETTINGS: 'Ban',
	UX_LANG_FORM_LAST_NAME: 'Last name',
	UX_LANG_FORM_FIRST_NAME: 'First name',
	UX_LANG_FORM_NAME: 'Last and First name',
	UX_LANG_FORM_EMAIL: 'E-mail',
	UX_LANG_FORM_COMPANY_NAME: 'Company Name',
	UX_LANG_MEMBERSHIP_FORM_TITLE: 'Membership form',
	UX_LANG_MEMBERSHIP_FORM_MEMBERSHIP_TYPE: 'Membership type',
	UX_LANG_MEMBERSHIP_FORM_TYPE_GENERAL_MEMBERSHIP: 'General Membership',
	UX_LANG_MEMBERSHIP_FORM_TYPE_SPONSORSHIP_MEMBERSHIP: 'Sponsor Membership',
	UX_LANG_MEMBERSHIP_FORM_TYPE_ASSOCIATE_MEMBERSHIP: 'Associate Membership',
	UX_LANG_MEMBERSHIP_FORM_MEMBERSHIP_MOTIVATION: 'Motivation for joining',
	UX_LANG_MEMBERSHIP_FORM_TYPE_BUSINESS_EXAMPLE:
		'Be an example for other companies',
	UX_LANG_MEMBERSHIP_FORM_TYPE_CONSERVATION_RELATED_INFORMATION:
		'To obtain and/or share information about conservation',
	UX_LANG_MEMBERSHIP_FORM_TYPE_INTERNATIONAL_RECOGNITION:
		'To gain local or international recognition',
	UX_LANG_MEMBERSHIP_FORM_TYPE_PARTICIPATION_CAPACITY_BUILDING_EVENTS:
		'To participate in events related to capacity building',
	UX_LANG_MEMBERSHIP_FORM_TYPE_CONTRIBUTION_TO_CONSERVATION_NATURE:
		'To contribute to the conservation of nature',
	UX_LANG_MEMBERSHIP_FORM_TYPE_PUBLICIZE_SHARE_ACTIVITIES:
		'Publicize / share conservation activities',
	UX_LANG_MEMBERSHIP_FORM_TYPE_ESTABLISH_CONTACTS: 'Make contacts',
	UX_LANG_MEMBERSHIP_FORM_TYPE_OTHERS: 'Others',
	UX_LANG_MEMBERSHIP_FORM_MOTIVATION_DESCRIPTION: 'Describe your motivation',
	UX_LANG_MEMBERSHIP_FORM_BUTTON: 'Join',
	UX_LANG_NEWSLETTER_FORM_TITLE: 'Subscribe to our newsletter',
	UX_LANG_NEWSLETTER_SUCCESS:
		'Your subscription to the newsletter was successful',
	UX_LANG_NEWSLETTER_FORM_BUTTON: 'Subscribe',
	UX_SHARE_ON_SOCIAL_MEDIA: 'Share on {socialMediaName}',
} as OI18nDefinition;
