import {isPlainObject, OWebUser} from 'oweb';
import {toInstance} from 'gobl-utils-ts';
import {OZUser} from "@/gobl";

export default class User<UserEntity extends OZUser = OZUser> extends OWebUser<UserEntity> {
	get current(): UserEntity | null {
		const data = this.getCurrent();
		let user;

		if (data && data.id && typeof data.getId === 'function') {
			return data;
		} else if (isPlainObject(data) && (user = toInstance(data, true))) {
			return user as any;
		}

		return null;
	}
}
