(function () {
	var hasTouchCapabilities =
		'ontouchstart' in window &&
		(navigator.maxTouchPoints || navigator.msMaxTouchPoints);
	var isTouchDevice = hasTouchCapabilities ? 'maybe' : 'nope';

	$(window).one('touchstart mousemove click', function (e) {
		if (isTouchDevice === 'maybe' && e.type === 'touchstart') {
			isTouchDevice = 'yes';
		}
	});

	//--------------------------------------

	$('.nav-menu-mobile-opener').on('click', function () {
		$('#nav-menu-wrap').toggleClass('ux-nav-menu-opened');
	});

	var $player = $('.wgt-popup-player');
	$(document.body)
		.on('mouseenter', '.mobile-header-button', function () {
			if (isTouchDevice === 'nope') {
				$(this).addClass('mouse-hover');
			}
		})
		.on('mouseleave', '.mobile-header-button', function () {
			$(this).removeClass('mouse-hover');
		})
		.on('click', '.popup-player-show', function () {
			var videoUrl = $(this).attr('data-video-url');
			if (videoUrl) {
				$('body').addClass('ux-has-active-modal');
				$player
					.find('.wgt-popup-player-body')
					.html(
						'<iframe src="' +
						videoUrl +
						'" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen frameborder="0" width="1000"></iframe>'
					);
				$player.fadeIn();
			}
		})
		.on('click', '.popup-player-close', function () {
			$player.fadeOut(function () {
				$player.find('.wgt-popup-player-body').empty();
				$('body').removeClass('ux-has-active-modal');
			});
		});

	$('.ux-splash-container').fadeOut(function () {
		$('body').removeClass('ux-has-active-modal');
	});
})();
