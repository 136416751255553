import {OWebApp} from 'oweb';
import UsersStore from '@/services/users';
import PagesStore from '@/services/pages';
import NewslettersStore from '@/services/newsletters';
import ChannelsStore from '@/services/channels';
import MessagesStore from '@/services/messages';

const servicesBundle = function (this: OWebApp) {
	console.log('[App] services bundle created for %s.', this.getAppName());

	return {
		users: new UsersStore(this),
		pages: new PagesStore(this),
		newsletters: new NewslettersStore(this),
		channels: new ChannelsStore(this),
		messages: new MessagesStore(this),
	};
};

export default servicesBundle;
