
import BaseComponent from '@/components/BaseComponent.vue';
import WEditorWidgets from '@/editor/components/WEditorWidgets.vue';
import WEditorForm from '@/editor/components/WEditorForm.vue';
import WEditorCanvas from '@/editor/components/WEditorCanvas.vue';
import WEditorPageConfigs from '@/editor/components/WEditorPageConfigs.vue';

export default BaseComponent.extend({
	name: 'WEditor',
	components: {
		WEditorPageConfigs,
		WEditorCanvas,
		WEditorForm,
		WEditorWidgets,
	},
});
