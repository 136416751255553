import {OWebForm} from 'oweb';
import OWebTelInput from 'oweb-tel-input';

OWebForm.declareFieldValidator(
	'phone', function (value: any, name: string, fv: OWebForm) {
		fv.assert(
			OWebTelInput.isPhoneNumberPossible(value, true),
			'OZ_FIELD_PHONE_INVALID'
		);
		let t_obj = new OWebTelInput({phoneNumber: value}),
			phone: string = t_obj.getInput(),
			cc2: string = t_obj.getCurrentCountry().cc2;

		fv.setFieldValue(name, phone.replace(/[ -]/g, ''));

		// set only if it is not already set
		// we may have multiple phone field or a cc2 field
		if (!fv.getFieldValue('cc2')) {
			fv.setFieldValue('cc2', cc2);
		}
	});
