
import WWidgetBaseWithChildren from '@/editor/components/widgets/WWidgetBaseWithChildren.vue';
import WEditorWidgetView from '@/editor/components/WEditorWidgetView.vue';

export default WWidgetBaseWithChildren.extend({
	name: 'WWidgetContainer',
	props: ['containerBg', 'containerPadding', 'innerStyle'],
	components: {WEditorWidgetView},
	computed: {
		style: function () {
			return {
				'--container-bg': this.containerBg,
				'--container-padding': this.containerPadding + 'px',
			};
		},
	},
});
