
/**
 * Auto generated file
 *
 * WARNING: please don't edit.
 *
 * Proudly With: gobl v1.5.0
 * Time: 1651227193
 */

import {
	GoblEntity,
	_bool,
	_int,
	_string,
} from 'gobl-utils-ts';

export default abstract class UCPageBase extends GoblEntity {
	public static readonly PREFIX: string = 'page';
	public static readonly COLUMNS: string[] = [
	'page_id',
	'page_parent_id',
	'page_type',
	'page_lang',
	'page_title',
	'page_description',
	'page_content',
	'page_image',
	'page_slug',
	'page_ref',
	'page_custom_data',
	'page_create_time',
	'page_update_time',
	'page_valid',

	];
	public static readonly COL_ID: string = 'page_id';
	public static readonly COL_PARENT_ID: string = 'page_parent_id';
	public static readonly COL_TYPE: string = 'page_type';
	public static readonly COL_LANG: string = 'page_lang';
	public static readonly COL_TITLE: string = 'page_title';
	public static readonly COL_DESCRIPTION: string = 'page_description';
	public static readonly COL_CONTENT: string = 'page_content';
	public static readonly COL_IMAGE: string = 'page_image';
	public static readonly COL_SLUG: string = 'page_slug';
	public static readonly COL_REF: string = 'page_ref';
	public static readonly COL_CUSTOM_DATA: string = 'page_custom_data';
	public static readonly COL_CREATE_TIME: string = 'page_create_time';
	public static readonly COL_UPDATE_TIME: string = 'page_update_time';
	public static readonly COL_VALID: string = 'page_valid';

	public singlePKValue() {
		return this.id;
	}
	public identifierColumns() {
		return [ UCPageBase.COL_ID ];
	}
	get id() { return _int(this._data[UCPageBase.COL_ID]); }
	set id(nVal: any) { this._set(UCPageBase.COL_ID, _int(nVal)); }
	public getId() { return this.id; }
	public setId(nVal: any): this { this.id = nVal; return this; }

	get parent_id() { return _int(this._data[UCPageBase.COL_PARENT_ID]); }
	set parent_id(nVal: any) { this._set(UCPageBase.COL_PARENT_ID, _int(nVal)); }
	public getParentId() { return this.parent_id; }
	public setParentId(nVal: any): this { this.parent_id = nVal; return this; }

	get type() { return _string(this._data[UCPageBase.COL_TYPE]); }
	set type(nVal: any) { this._set(UCPageBase.COL_TYPE, _string(nVal)); }
	public getType() { return this.type; }
	public setType(nVal: any): this { this.type = nVal; return this; }

	get lang() { return _string(this._data[UCPageBase.COL_LANG]); }
	set lang(nVal: any) { this._set(UCPageBase.COL_LANG, _string(nVal)); }
	public getLang() { return this.lang; }
	public setLang(nVal: any): this { this.lang = nVal; return this; }

	get title() { return _string(this._data[UCPageBase.COL_TITLE]); }
	set title(nVal: any) { this._set(UCPageBase.COL_TITLE, _string(nVal)); }
	public getTitle() { return this.title; }
	public setTitle(nVal: any): this { this.title = nVal; return this; }

	get description() { return _string(this._data[UCPageBase.COL_DESCRIPTION]); }
	set description(nVal: any) { this._set(UCPageBase.COL_DESCRIPTION, _string(nVal)); }
	public getDescription() { return this.description; }
	public setDescription(nVal: any): this { this.description = nVal; return this; }

	get content() { return _string(this._data[UCPageBase.COL_CONTENT]); }
	set content(nVal: any) { this._set(UCPageBase.COL_CONTENT, _string(nVal)); }
	public getContent() { return this.content; }
	public setContent(nVal: any): this { this.content = nVal; return this; }

	get image() { return _string(this._data[UCPageBase.COL_IMAGE]); }
	set image(nVal: any) { this._set(UCPageBase.COL_IMAGE, _string(nVal)); }
	public getImage() { return this.image; }
	public setImage(nVal: any): this { this.image = nVal; return this; }

	get slug() { return _string(this._data[UCPageBase.COL_SLUG]); }
	set slug(nVal: any) { this._set(UCPageBase.COL_SLUG, _string(nVal)); }
	public getSlug() { return this.slug; }
	public setSlug(nVal: any): this { this.slug = nVal; return this; }

	get ref() { return _string(this._data[UCPageBase.COL_REF]); }
	set ref(nVal: any) { this._set(UCPageBase.COL_REF, _string(nVal)); }
	public getRef() { return this.ref; }
	public setRef(nVal: any): this { this.ref = nVal; return this; }

	get custom_data() { return _string(this._data[UCPageBase.COL_CUSTOM_DATA]); }
	set custom_data(nVal: any) { this._set(UCPageBase.COL_CUSTOM_DATA, _string(nVal)); }
	public getCustomData() { return this.custom_data; }
	public setCustomData(nVal: any): this { this.custom_data = nVal; return this; }

	get create_time() { return _string(this._data[UCPageBase.COL_CREATE_TIME]); }
	set create_time(nVal: any) { this._set(UCPageBase.COL_CREATE_TIME, _string(nVal)); }
	public getCreateTime() { return this.create_time; }
	public setCreateTime(nVal: any): this { this.create_time = nVal; return this; }

	get update_time() { return _string(this._data[UCPageBase.COL_UPDATE_TIME]); }
	set update_time(nVal: any) { this._set(UCPageBase.COL_UPDATE_TIME, _string(nVal)); }
	public getUpdateTime() { return this.update_time; }
	public setUpdateTime(nVal: any): this { this.update_time = nVal; return this; }

	get valid() { return _bool(this._data[UCPageBase.COL_VALID]); }
	set valid(nVal: any) { this._set(UCPageBase.COL_VALID, _bool(nVal)); }
	public getValid() { return this.valid; }
	public setValid(nVal: any): this { this.valid = nVal; return this; }


}
