
import BaseComponent from '@/components/BaseComponent.vue';
import WEditorWidgetView from '@/editor/components/WEditorWidgetView.vue';

export default BaseComponent.extend({
	name: 'WEditorCanvas',
	components: {
		WEditorWidgetView,
	},
});
