
/**
 * Auto generated file
 *
 * WARNING: please don't edit.
 *
 * Proudly With: gobl v1.5.0
 * Time: 1651227193
 */

import {
	GoblEntity,
	_bool,
	_int,
	_string,
} from 'gobl-utils-ts';

export default abstract class UCMessageBase extends GoblEntity {
	public static readonly PREFIX: string = 'message';
	public static readonly COLUMNS: string[] = [
	'message_id',
	'message_author_id',
	'message_channel_id',
	'message_reply_of',
	'message_content',
	'message_type',
	'message_custom_data',
	'message_create_time',
	'message_update_time',
	'message_valid',

	];
	public static readonly COL_ID: string = 'message_id';
	public static readonly COL_AUTHOR_ID: string = 'message_author_id';
	public static readonly COL_CHANNEL_ID: string = 'message_channel_id';
	public static readonly COL_REPLY_OF: string = 'message_reply_of';
	public static readonly COL_CONTENT: string = 'message_content';
	public static readonly COL_TYPE: string = 'message_type';
	public static readonly COL_CUSTOM_DATA: string = 'message_custom_data';
	public static readonly COL_CREATE_TIME: string = 'message_create_time';
	public static readonly COL_UPDATE_TIME: string = 'message_update_time';
	public static readonly COL_VALID: string = 'message_valid';

	public singlePKValue() {
		return this.id;
	}
	public identifierColumns() {
		return [ UCMessageBase.COL_ID ];
	}
	get id() { return _string(this._data[UCMessageBase.COL_ID]); }
	set id(nVal: any) { this._set(UCMessageBase.COL_ID, _string(nVal)); }
	public getId() { return this.id; }
	public setId(nVal: any): this { this.id = nVal; return this; }

	get author_id() { return _string(this._data[UCMessageBase.COL_AUTHOR_ID]); }
	set author_id(nVal: any) { this._set(UCMessageBase.COL_AUTHOR_ID, _string(nVal)); }
	public getAuthorId() { return this.author_id; }
	public setAuthorId(nVal: any): this { this.author_id = nVal; return this; }

	get channel_id() { return _string(this._data[UCMessageBase.COL_CHANNEL_ID]); }
	set channel_id(nVal: any) { this._set(UCMessageBase.COL_CHANNEL_ID, _string(nVal)); }
	public getChannelId() { return this.channel_id; }
	public setChannelId(nVal: any): this { this.channel_id = nVal; return this; }

	get reply_of() { return _string(this._data[UCMessageBase.COL_REPLY_OF]); }
	set reply_of(nVal: any) { this._set(UCMessageBase.COL_REPLY_OF, _string(nVal)); }
	public getReplyOf() { return this.reply_of; }
	public setReplyOf(nVal: any): this { this.reply_of = nVal; return this; }

	get content() { return _string(this._data[UCMessageBase.COL_CONTENT]); }
	set content(nVal: any) { this._set(UCMessageBase.COL_CONTENT, _string(nVal)); }
	public getContent() { return this.content; }
	public setContent(nVal: any): this { this.content = nVal; return this; }

	get type() { return _string(this._data[UCMessageBase.COL_TYPE]); }
	set type(nVal: any) { this._set(UCMessageBase.COL_TYPE, _string(nVal)); }
	public getType() { return this.type; }
	public setType(nVal: any): this { this.type = nVal; return this; }

	get custom_data() { return _string(this._data[UCMessageBase.COL_CUSTOM_DATA]); }
	set custom_data(nVal: any) { this._set(UCMessageBase.COL_CUSTOM_DATA, _string(nVal)); }
	public getCustomData() { return this.custom_data; }
	public setCustomData(nVal: any): this { this.custom_data = nVal; return this; }

	get create_time() { return _string(this._data[UCMessageBase.COL_CREATE_TIME]); }
	set create_time(nVal: any) { this._set(UCMessageBase.COL_CREATE_TIME, _string(nVal)); }
	public getCreateTime() { return this.create_time; }
	public setCreateTime(nVal: any): this { this.create_time = nVal; return this; }

	get update_time() { return _string(this._data[UCMessageBase.COL_UPDATE_TIME]); }
	set update_time(nVal: any) { this._set(UCMessageBase.COL_UPDATE_TIME, _string(nVal)); }
	public getUpdateTime() { return this.update_time; }
	public setUpdateTime(nVal: any): this { this.update_time = nVal; return this; }

	get valid() { return _bool(this._data[UCMessageBase.COL_VALID]); }
	set valid(nVal: any) { this._set(UCMessageBase.COL_VALID, _bool(nVal)); }
	public getValid() { return this.valid; }
	public setValid(nVal: any): this { this.valid = nVal; return this; }


}
