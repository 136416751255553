import {OWebApp, OWebServiceStore} from 'oweb';
import {UCChannel} from '@/gobl';

export default class ChannelsStore extends OWebServiceStore<UCChannel> {
	public static readonly CHANNEL_TYPE_PUBLIC = 'PUBLIC';
	public static readonly CHANNEL_TYPE_PRIVATE = 'PRIVATE';
	public static readonly CHANNEL_TYPE_DIRECT_MESSAGE = 'DIRECT_MESSAGE';

	constructor(app: OWebApp) {
		super(app, UCChannel, 'channels');
	}
}
