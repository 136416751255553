import { wm } from '@/editor/utils';

// ======== wgt-headline
wm.add({
	name: 'wgt-headline',
	component: () => import('@/components/WgtHeadline.vue'),
	label: 'UICN Headline',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-headline.png'),
	options: {
		props: {
			title: {
				type: 'textarea',
				default: 'A la une',
				label: 'Title',
			},
			fontSize: {
				type: 'number',
				label: 'Taille du titre (en REM)',
				default: 3.5,
				typeProps: {
					min: 2,
					step: 0.1,
				},
			},
			mobileFontSize: {
				type: 'number',
				label: 'Taille du titre sur mobile (en REM)',
				default: 2.5,
				typeProps: {
					min: 2,
					step: 0.1,
				},
			},
		},
	},
});

// ======== wgt-search
wm.add({
	name: 'wgt-search',
	component: () => import('@/components/WgtSearch.vue'),
	label: 'UICN Search',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-search.png'),
	options: {
		props: {
			placeholder: {
				type: 'text',
				default: 'Rechercher...',
				label: 'Placeholder',
			},
		},
	},
});

// ======== wgt-news-card-a
wm.add({
	name: 'wgt-news-card-a',
	component: () => import('@/components/WgtNewsCardA.vue'),
	label: 'UICN News Card A',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-news-card-a.png'),
	options: {
		props: {
			image: {
				type: 'image',
				default: {
					src: require('@/assets/images/placeholder-ratio-800x600.png'),
					label: 'Forêt RDC',
				},
				label: 'Image 800x600',
			},
			title: {
				type: 'text',
				default: 'RDC, la forêt des gorilles incendiée',
			},
			description: {
				type: 'textarea',
				default:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
			},
			btn: {
				type: 'url',
				default: {
					url: '#',
					label: 'EN SAVOIR PLUS',
				},
			},
			shadow: {
				type: 'bool',
				default: false,
				label: 'Mettre une élevation',
			},
			verticalLayout: {
				type: 'bool',
				default: false,
				label: 'Disposer verticalement',
			},
		},
	},
});

// ======== wgt-news-card-b
wm.add({
	name: 'wgt-news-card-b',
	component: () => import('@/components/WgtNewsCardB.vue'),
	label: 'UICN News Card B',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-news-card-b.png'),
	options: {
		props: {
			image: {
				type: 'image',
				default: {
					src: require('@/assets/images/placeholder-ratio-800x600.png'),
					label: 'Forêt RDC',
				},
				label: 'Image 800x600',
			},
			title: {
				type: 'text',
				default: 'RDC, la forêt des gorilles incendiée',
			},
			description: {
				type: 'textarea',
				default:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
			},
			btn: {
				type: 'url',
				default: {
					url: '#',
					label: 'Lire+',
				},
			},
		},
	},
});

// ======== wgt-custom-title-centered
wm.add({
	name: 'wgt-custom-title-centered',
	component: () => import('@/components/WgtCustomTitleCentered.vue'),
	label: 'UICN Custom Title Centered',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-custom-title-centered.png'),
	options: {
		props: {
			title: {
				type: 'text',
				label: 'Titre',
				default: 'Bienvenue sur le forum des entreprises',
			},
			titleFontSize: {
				type: 'number',
				label: 'Taille du titre (en REM)',
				default: 2,
				typeProps: {
					min: 1.2,
					step: 0.1,
				},
			},
			subTitle: {
				type: 'textarea',
				label: 'Sous-Titre',
				default:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna',
			},
			subTitleFontSize: {
				type: 'number',
				label: 'Taille du sous-titre (en REM)',
				default: 1.5,
				typeProps: {
					min: 1,
					step: 0.1,
				},
			},
		},
	},
});

// ======== wgt-counter-numbers
wm.add({
	name: 'wgt-counter-numbers',
	component: () => import('@/components/WgtCounterNumbers.vue'),
	label: 'UICN Counter Numbers',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-counter-number.png'),
	options: {
		props: {
			items: {
				type: 'list',
				typeProps: {
					min: 1,
					max: 4,
					itemName: 'Stat',
					itemProps: {
						counter: {
							type: 'text',
							label: 'Compteur',
							default: '275+',
						},
						title: {
							type: 'text',
							label: 'Titre',
							default:
								"(ONG) Organisation Non Gouvernementale s'occupant de la nature africaine.",
						},
					},
				},
				default: [],
			},
		},
	},
});

// ======== wgt-card-master-event
wm.add({
	name: 'wgt-card-master-forum',
	component: () => import('@/components/WgtCardMasterEvent.vue'),
	label: 'UICN Card Master Forum',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-card-master-event.png'),
	options: {
		props: {
			image: {
				type: 'image',
				default: {
					src: require('@/assets/images/uicn-aliko-dangote.png'),
					label: 'Aliko Dangoté',
				},
				label: 'Image',
			},
			name: {
				type: 'text',
				default: 'Aliko Dangoté',
				label: 'Nom du profil',
			},
			profileDescription: {
				type: 'textarea',
				default:
					'Expert dans la gestion des ressources minières et forestière.',
				label: 'Courte description du profil',
			},
			description: {
				type: 'textarea',
				default:
					'Lorem ipsum dolor sit amet, consectur adipscing elit, sed do eusmod tempor incididunt ut labore et dolore magna aliqua.',
				label: "Description de l'évènement",
			},
			title: {
				label: "Nom de l'évènement",
				type: 'textarea',
				default:
					'Masterclass sur les ressources minières et leurs gestions au Niger.',
			},
			date: {
				label: 'Date',
				type: 'text',
				default: '11 Juin 2022',
			},
			duration: {
				label: 'Time',
				type: 'text',
				default: 'De 11h à 18h sur Facebook Live',
			},
			participantCounter: {
				label: 'Nombre de participants',
				type: 'number',
				default: 100,
			},
			commentCounter: {
				label: 'Nombre de posts',
				type: 'number',
				default: 1000,
			},
			backgroundImage: {
				type: 'image',
				default: {
					src: require('@/assets/images/uicn-djegbadji.png'),
					label: 'Aliko Dangoté',
				},
				label: 'Image illustrative',
			},
		},
	},
});

// ======== wgt-card-join-forum
wm.add({
	name: 'wgt-card-join-forum',
	component: () => import('@/components/WgtCardJoinForum.vue'),
	label: 'UICN Card Join Forum',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-card-join-forum.png'),
	options: {
		props: {
			title: {
				type: 'textarea',
				default: 'Bienvenue sur le forum des entreprises',
				label: "Titre de l'invitation",
			},
			subtitle: {
				type: 'text',
				default: 'Lorem ipsum dolor sit amet, consectur adipscing elit',
				label: "Sous-titre de l'invitation",
			},
			description: {
				type: 'textarea',
				default:
					'Lorem ipsum dolor sit amet, consectur adipscing elit, sed do eusmod tempor incididunt ut labore et dolore magna aliqua.',
				label: "Description de l'invitation",
			},
			participantCounter: {
				label: 'Nombre de participants',
				type: 'number',
				default: 100,
			},
			commentCounter: {
				label: 'Nombre de posts',
				type: 'number',
				default: 1000,
			},
		},
	},
});

// ======== wgt-card-event
wm.add({
	name: 'wgt-card-event',
	component: () => import('@/components/WgtCardEvent.vue'),
	label: 'UICN Card Event',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-card-event.png'),
	options: {
		props: {
			backgroundImage: {
				type: 'image',
				label: 'Image de fond',
				default: {
					src: require('@/assets/images/uicn-mer-x.png'),
					label: 'UICN Mer',
				},
			},
			title: {
				type: 'textarea',
				label: "Titre de l'invitation",
				default: "Salon sur l'évolution de la mer et ses conséquences",
			},
			description: {
				type: 'textarea',
				label: "Description de l'invitation",
				default:
					'Lorem ipsum dolor sit amet, consectur adipscing elit, sed do eusmod tempor incididunt ut labore et dolore magna aliqua.',
			},
			date: {
				type: 'text',
				label: "Date de l'évènement",
				default: 'Du 10 au 23 Septembre 2022',
			},
			location: {
				type: 'text',
				default: 'Palais des congrès de Cotonou',
				label: "Lieu de l'évènement",
			},
			duration: {
				type: 'text',
				label: "Durée de l'évènement",
				default: 'De 10:00 à 18:30',
			},
		},
	},
});

// ======== wgt-card-event-custom
wm.add({
	name: 'wgt-card-event-custom',
	component: () => import('@/components/WgtCardEventCustom.vue'),
	label: 'UICN Card Event Custom',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-card-event-custom.png'),
	options: {
		props: {
			backgroundImage: {
				type: 'image',
				label: 'Image illustrative',
				default: {
					src: require('@/assets/images/uicn-djegbadji.png'),
					label: 'UICN Djegbadji',
				},
			},
			date: {
				type: 'text',
				label: "Date de l'évènement",
				default: '31 Septembre 2022',
			},
			title: {
				type: 'textarea',
				label: "Titre de l'évènement",
				default: "Salon sur l'évolution de la mer et ses conséquences",
			},
			description: {
				type: 'textarea',
				label: "Courte Description sur l'évènement",
				default:
					'Lorem ipsum dolor sit amet, consectur adipscing elit, sed do eusmod tempor incididunt ut labore et dolore magna aliqua.',
			},
			trainer: {
				type: 'text',
				default: 'John Doe',
				label: 'Présentateur',
			},
			countries: {
				type: 'list',
				typeProps: {
					min: 1,
					max: 20,
					itemName: 'Country',
					itemProps: {
						name: {
							type: 'text',
							label: 'Pays',
							default: 'Bénin',
						},
						id: {
							type: 'number',
							label: 'Id',
							default: 1,
						},
					},
				},
				default: [],
				label: 'Pays concernés',
			},
			time: {
				type: 'text',
				label: "Heure de l'énènement",
				default: '18:30',
			},
			location: {
				type: 'text',
				default: 'Palais des congrès de Cotonou',
				label: "Lieu de l'évènement",
			},
		},
	},
});

// ======== wgt-card-headline
wm.add({
	name: 'wgt-card-headline',
	component: () => import('@/components/WgtCardHeadline.vue'),
	label: 'UICN Card Headline',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-card-headline.png'),
	options: {
		props: {
			title: {
				type: 'text',
				default: 'A la une',
				label: 'Titre du Headline',
			},
			titleFontSize: {
				type: 'number',
				label: 'Taille du titre (en REM)',
				default: 3.5,
				typeProps: {
					min: 2,
					step: 0.1,
				},
			},
			titleMobileFontSize: {
				type: 'number',
				label: 'Taille du titre sur mobile (en REM)',
				default: 2.5,
				typeProps: {
					min: 2,
					step: 0.1,
				},
			},
			description: {
				type: 'textarea',
				default:
					'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\n' +
					'tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,\n' +
					'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\n' +
					'consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse\n' +
					'cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non\n' +
					'proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
				label: 'Description du Headline',
			},
			link: {
				type: 'url',
				default: {
					url: '#',
					label: 'Lire +',
				},
			},
			image: {
				type: 'image',
				label: 'Image du Headline',
				default: {
					src: require('@/assets/images/uicn-fleuve-niger.png'),
					label: 'UICN Fleuve Niger',
				},
			},
			imageDescription: {
				type: 'textarea',
				label: 'Courte Description de image',
				default:
					"Le fleuve Niger, dépotoire des résidu d'usine de transformation",
			},
		},
	},
});

// ========= wgt-leaders-members
wm.add({
	name: 'wgt-leaders-members',
	component: () => import('@/components/WgtLeadersMembers.vue'),
	label: 'UICN Leaders Members',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-leaders-members.png'),
	options: {
		props: {
			list: {
				type: 'list',
				typeProps: {
					min: 4,
					max: 20,
					itemName: 'Image',
					itemProps: {
						image: {
							type: 'image',
							label: 'Image du dirigeant',
							default: {
								src: require('@/assets/images/uicn-avatar-adult.png'),
								label: 'UICN Dirigeant',
							},
						},
					},
				},
				default: [],
				label: 'Images des dirigeants',
			},
		},
	},
});

// ========= wgt-news-slider
wm.add({
	name: 'wgt-news-slider',
	component: () => import('@/components/WgtNewsSlider.vue'),
	label: 'UICN News Slider',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-news-slider.png'),
	options: {
		props: {
			cards: {
				type: 'list',
				typeProps: {
					min: 1,
					max: 20,
					itemName: 'Card New',
					itemProps: {
						id: {
							type: 'number',
							label:
								'Id du Card (valeur incrémentée par rapport à la précédente)',
							default: '1',
							typeProps: {
								min: 1,
								step: 1,
							},
						},
						image: {
							type: 'image',
							label: 'Image Illustrative 800x600',
							default: {
								src: require('@/assets/images/placeholder-ratio-800x600.png'),
								label: 'Pêche',
							},
						},
						title: {
							type: 'text',
							default:
								"La vie marine et la pêche sont de plus en pus menacées par la perte d'oxygène",
							label: "Titre de l'article",
						},
						description: {
							type: 'textarea',
							default:
								"Gland, Suisse, 30 Octobre 2019 (UICN) - L'Union internationale pour la conservation de la nature(UICN) a ouvert les inscriptions pour les membres des médias pour le Congrès mondial de la nature de l'UICN 2020. L'Union Internationale pour la conservation de la nature cette réussite",
							label: "Description de l'article",
						},
						btn: {
							type: 'url',
							default: {
								url: '#',
								label: 'En savoir plus',
							},
						},
					},
				},
				default: [],
				label: 'Card Article',
			},
			buttons: {
				type: 'list',
				typeProps: {
					min: 3,
					max: 3,
					itemName: 'Button',
					itemProps: {
						btn: {
							type: 'url',
							default: {
								url: '#',
								label: 'Devenir partenaire',
							},
						},
						icon: {
							type: 'icon-font',
							default: 'fal fa-handshake',
							label: 'Icon du button',
						},
					},
				},
				default: [],
				label: 'Buttons',
			},
		},
	},
});

// ======== wgt-social-media-icons
wm.add({
	name: 'wgt-social-media-icons',
	component: () => import('@/components/WgtSocialMediaIcons.vue'),
	label: 'UICN Social Media Icons',
	options: {
		props: {
			title: {
				type: 'color',
				default: '#000',
			},
			targetUrl: {
				type: 'url',
				default: {
					url: '#',
					label: 'Share',
				},
			},
		},
	},
});

// ======== wgt-card-text-overlay
wm.add({
	name: 'wgt-card-text-overlay',
	component: () => import('@/components/WgtCardTextOverlay.vue'),
	label: 'UICN Card Text Overlay',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-card-text-overlay.png'),
	options: {
		props: {
			image: {
				type: 'image',
				default: {
					src: require('@/assets/images/uicn-kilimadjaro.png'),
				},
			},
			cardAction: {
				type: 'url',
				label: 'Card URL',
				default: {
					label: 'Ceci est un titre',
					url: '#',
				},
			},
			titleFontSize: {
				type: 'number',
				label: 'Taille du titre (en REM)',
				default: 2.5,
				typeProps: {
					min: 1.2,
					step: 0.1,
				},
			},
			gradientColor: {
				type: 'color',
				label: 'Couleur',
				default: '',
			},
			description: {
				type: 'textarea',
				label: 'Descritpion',
				default: '',
			},
			descriptionFontSize: {
				type: 'number',
				label: 'Taille de la description (en REM)',
				default: 1.2,
				typeProps: {
					min: 1.2,
					step: 0.1,
				},
			},
		},
	},
});

// ======== wgt-membership-form
wm.add({
	name: 'wgt-membership-form',
	component: () => import('@/components/WgtMembershipForm.vue'),
	label: 'UICN Membership Form',
	thumbnail: require('@/assets/images/thumbnails/thumb-wgt-membership-form.png'),
	options: {
		props: {},
	},
});

// ======== wgt-news-card-paginated
wm.add({
	name: 'wgt-news-card-paginated',
	component: () => import('@/components/WgtNewsCardPaginated.vue'),
	label: 'UICN Paginated News Card',
	options: {
		props: {
			categories: {
				type: 'list',
				typeProps: {
					min: 4,
					max: 6,
					itemName: 'Category',
					itemProps: {
						name: {
							type: 'text',
							default: 'Conservation de la forêt',
							label: 'Nom de la catégorie',
						},
						items: {
							type: 'list',
							typeProps: {
								itemName: 'Article',
								itemProps: {
									btn: {
										type: 'url',
										default: {
											url: '#',
											label: 'Lire plus',
										},
									},
									description: {
										type: 'textarea',
										default:
											'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
									},
									image: {
										type: 'image',
										default: {
											src: require('@/assets/images/uicn-kilimadjaro.png'),
										},
									},
									title: {
										type: 'text',
										default: 'Ceci est un titre',
									},
								},
							},
							default: [],
							label: 'Articles de la catégorie',
						},
					},
				},
				label: 'Catégories',
				default: [],
			},
			perPage: {
				type: 'number',
				label: "Nombre d'articles par page",
				default: 6,
			},
		},
	},
});

// ======== wgt-rich-text-with-toc

wm.add({
	name: 'wgt-rich-text-with-toc',
	component: () => import('@/components/WgtRichTextWithTOC.vue'),
	label: 'Texte riche avec sommaire',
	options: {
		props: {
			titleToc: {
				type: 'text',
				label: 'Titre de la table de contenus',
				default: 'Table de contenus',
			},
			content: {
				type: 'textarea',
				typeProps: {
					rows: 12,
				},
				default: `# Titre niveau 1
## Titre niveau 2
### Titre niveau 3
#### Titre niveau 4
##### Titre niveau 5
###### Titre niveau 6
`,
			},
		},
	},
});
