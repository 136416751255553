import { render, staticRenderFns } from "./WLoaderFull.vue?vue&type=template&id=c9d35b86&scoped=true&"
import script from "./WLoaderFull.vue?vue&type=script&lang=ts&"
export * from "./WLoaderFull.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_vsyllalxesco42bbuzcqgel7xq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9d35b86",
  null
  
)

export default component.exports