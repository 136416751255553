
import WWidgetBase from '@/editor/components/widgets/WWidgetBase.vue';
import {wm} from '@/editor/utils';
import Vue from 'vue';

const loadedImagesCache: Record<string, boolean> = {};
const sliderOptions = {
	item: 1,
	adaptiveHeight: true,
	slideMargin: 0,
	auto: true,
	loop: true,
	pauseOnHover: true,
	speed: 600,
	pause: 4000,
	//enableDrag  : false,
};

export default WWidgetBase.extend({
	name: 'WWidgetSlider',
	props: ['slides'],
	data: function () {
		return {
			sliderApi: null as any,
			loadedSlides: {} as Record<number, TPageWidgetEntry>,
			preload: false,
		};
	},
	computed: {
		loadedSlideCount: function () {
			//@ts-ignore
			return Object.keys(this.loadedSlides).length;
		},
	},
	methods: {
		loadImage: function (index: number, slide: TPageWidgetEntry) {
			const x = this;
			let src = slide.data.image.src;

			if (loadedImagesCache[src]) {
				if (!x.loadedSlides[index]) {
					Vue.set(x.loadedSlides, index, slide);
				}
				return;
			}

			let img = new Image();
			img.src = src;
			img.onload = function () {
				loadedImagesCache[src] = true;
				Vue.set(x.loadedSlides, index, slide);
				document.body.removeChild(img);
				x.initSlider();
			};

			img.onerror = function () {
				document.body.removeChild(img);

				setTimeout(function () {
					x.loadImage(index, slide);
				}, 5000);
			};

			img.style.width = '1px';
			img.style.height = '1px';
			img.style.opacity = '0.1';

			document.body.appendChild(img);
		},
		slideClasses: function (slide: TPageWidgetEntry) {
			return ['wgt-slide--type-' + (slide.data.slideType || 'c')];
		},
		slideStyles: function (slide: TPageWidgetEntry) {
			let s: any = {};

			slide.data.overlyBg && (s['--slide-overly-bg'] = slide.data.overlyBg);
			slide.data.btnBg && (s['--slide-btn-bg'] = slide.data.btnBg);
			slide.data.btnColor && (s['--slide-btn-color'] = slide.data.btnColor);
			slide.data.titleColor && (s['--slide-title-color'] = slide.data.titleColor);
			slide.data.sutitleColor && (s['--slide-subtitle-color'] = slide.data.sutitleColor);
			slide.data.titleSize && (s['--slide-title-size'] = slide.data.titleSize + 'rem');
			slide.data.subtitleSize && (s['--slide-subtitle-size'] = slide.data.subtitleSize + 'rem');

			return s;
		},

		initSlider: function () {
			if (this.preload) {
				for (var i = 0; i < this.slides.length; i++) {
					this.loadImage(i, this.slides[i]);
				}
			} else {
				this.loadedSlides = this.slides;
			}

			var el = this.$refs.slider_container;
			if (!el) {
				return;
			}

			if (this.sliderApi) {
				this.sliderApi.refresh();
			} else {
				this.sliderApi = ($(el) as any).lightSlider(wm.copy(sliderOptions));
			}
		},
	},
	updated: function () {
		var ctx = this;
		this.$nextTick(function () {
			ctx.initSlider();
		});
	},
	mounted: function () {
		var ctx = this;
		this.$nextTick(function () {
			ctx.initSlider();
		});
	},
});
