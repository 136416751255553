
import WWidgetBase from '@/editor/components/widgets/WWidgetBase.vue';
import {parseMarkdown} from '@/editor/utils';

export default WWidgetBase.extend({
	name: 'WWidgetRichText',
	props: ['content'],
	computed: {
		htmlContent: function () {
			return parseMarkdown(this.content);
		},
	},
});
