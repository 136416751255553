
import BaseComponent from "@/components/BaseComponent.vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import tippy, {Instance, MultipleTargets, Props as TippyProps} from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import {logger} from "oweb";

const toArray = (val: unknown) => (Array.isArray(val) ? val : [val]);

const useTippy = function useTippy(options: Partial<TippyProps>, target: MultipleTargets | null = null) {
	let instance: Instance<TippyProps> | Instance<TippyProps>[] | null = null;
	const actions = {
		create() {
			if (!target) {
				logger.warn('useTippy:cant-create missing target');
				return;
			}
			logger.log('useTippy:create', instance, options);
			actions.destroy();
			instance = tippy(target, options);
		},
		destroy() {
			logger.log('useTippy:destroy', instance);
			instance &&
			toArray(instance).forEach((t: Instance) => t.destroy());
		},
		update(newOptions: Partial<TippyProps>) {
			logger.log('useTippy:update', instance);
			instance &&
			toArray(instance).forEach((t: Instance) => t.setProps(newOptions));
		}
	};

	return {
		tippy: instance,
		target,
		actions,
	};
}

@Component
export default class WTippy extends BaseComponent {

	@Prop({type: String, default: 'div'})
	tag!: string;
	@Prop({type: Object, default: {}})
	options!: TippyProps;

	context: ReturnType<typeof useTippy> | null = null;
	changeCount = 1;

	@Watch('options', {immediate: false, deep: true})
	watchOptions() {
		this.context && this.context.actions.update(this.getTippyOptions());
	}

	getTippyOptions() {
		return {...this.options, content: this.$refs.content as Element}
	}

	mounted() {
		const context = this.context = useTippy(this.getTippyOptions(), this.$refs.trigger as any);

		this.$nextTick(() => {
			context.actions.create()
		});
	}

	beforeUnmount() {
		this.context && this.context.actions.destroy();
	}
}
