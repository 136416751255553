
import app from '@/app';
import BaseComponent from '@/components/BaseComponent.vue';

export default BaseComponent.extend({
	name: 'WEditorPageConfigs',
	data: function () {
		const po = app.store.editorRoot.pageOptions;
		return {
			global_slider_enabled: po.global_slider_enabled !== false ? 1 : 0,
		};
	},
	watch: {
		global_slider_enabled: function (v: string) {
			this.$store.editorRoot.setOption('global_slider_enabled', !!parseInt(v));
		},
	},
	computed: {
		shouldSave: function () {
			if (this.$store.editorRoot.isViewMode) {
				return false;
			}

			const d = this.$store.editorRoot.serializePageContent();
			return (
				this.$store.editorRoot.saveCount &&
				d !== this.$store.editorRoot.pageContentInitial
			);
		},
	},
	created: function () {
		const x = this;
		window.addEventListener('beforeunload', function (event) {
			// Cancel the event as stated by the standard.
			// event.preventDefault();
			// Chrome requires returnValue to be set.
			if (x.shouldSave) {
				return (event.returnValue =
					"Vous n'avez pas enrégistré les modifications.");
			}
		});
	},
});
