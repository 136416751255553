
/**
 * Auto generated file
 *
 * WARNING: please don't edit.
 *
 * Proudly With: gobl v1.5.0
 * Time: 1651227193
 */

import {
	GoblEntity,
	_bool,
	_int,
	_string,
} from 'gobl-utils-ts';

export default abstract class UCNewsletterBase extends GoblEntity {
	public static readonly PREFIX: string = 'newsletter';
	public static readonly COLUMNS: string[] = [
	'newsletter_id',
	'newsletter_name',
	'newsletter_email',
	'newsletter_read',
	'newsletter_custom_data',
	'newsletter_create_time',
	'newsletter_update_time',
	'newsletter_valid',

	];
	public static readonly COL_ID: string = 'newsletter_id';
	public static readonly COL_NAME: string = 'newsletter_name';
	public static readonly COL_EMAIL: string = 'newsletter_email';
	public static readonly COL_READ: string = 'newsletter_read';
	public static readonly COL_CUSTOM_DATA: string = 'newsletter_custom_data';
	public static readonly COL_CREATE_TIME: string = 'newsletter_create_time';
	public static readonly COL_UPDATE_TIME: string = 'newsletter_update_time';
	public static readonly COL_VALID: string = 'newsletter_valid';

	public singlePKValue() {
		return this.id;
	}
	public identifierColumns() {
		return [ UCNewsletterBase.COL_ID ];
	}
	get id() { return _int(this._data[UCNewsletterBase.COL_ID]); }
	set id(nVal: any) { this._set(UCNewsletterBase.COL_ID, _int(nVal)); }
	public getId() { return this.id; }
	public setId(nVal: any): this { this.id = nVal; return this; }

	get name() { return _string(this._data[UCNewsletterBase.COL_NAME]); }
	set name(nVal: any) { this._set(UCNewsletterBase.COL_NAME, _string(nVal)); }
	public getName() { return this.name; }
	public setName(nVal: any): this { this.name = nVal; return this; }

	get email() { return _string(this._data[UCNewsletterBase.COL_EMAIL]); }
	set email(nVal: any) { this._set(UCNewsletterBase.COL_EMAIL, _string(nVal)); }
	public getEmail() { return this.email; }
	public setEmail(nVal: any): this { this.email = nVal; return this; }

	get read() { return _bool(this._data[UCNewsletterBase.COL_READ]); }
	set read(nVal: any) { this._set(UCNewsletterBase.COL_READ, _bool(nVal)); }
	public getRead() { return this.read; }
	public setRead(nVal: any): this { this.read = nVal; return this; }

	get custom_data() { return _string(this._data[UCNewsletterBase.COL_CUSTOM_DATA]); }
	set custom_data(nVal: any) { this._set(UCNewsletterBase.COL_CUSTOM_DATA, _string(nVal)); }
	public getCustomData() { return this.custom_data; }
	public setCustomData(nVal: any): this { this.custom_data = nVal; return this; }

	get create_time() { return _string(this._data[UCNewsletterBase.COL_CREATE_TIME]); }
	set create_time(nVal: any) { this._set(UCNewsletterBase.COL_CREATE_TIME, _string(nVal)); }
	public getCreateTime() { return this.create_time; }
	public setCreateTime(nVal: any): this { this.create_time = nVal; return this; }

	get update_time() { return _string(this._data[UCNewsletterBase.COL_UPDATE_TIME]); }
	set update_time(nVal: any) { this._set(UCNewsletterBase.COL_UPDATE_TIME, _string(nVal)); }
	public getUpdateTime() { return this.update_time; }
	public setUpdateTime(nVal: any): this { this.update_time = nVal; return this; }

	get valid() { return _bool(this._data[UCNewsletterBase.COL_VALID]); }
	set valid(nVal: any) { this._set(UCNewsletterBase.COL_VALID, _bool(nVal)); }
	public getValid() { return this.valid; }
	public setValid(nVal: any): this { this.valid = nVal; return this; }


}
