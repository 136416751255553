import { render, staticRenderFns } from "./TTypeColor.vue?vue&type=template&id=3b7e9500&scoped=true&"
import script from "./TTypeColor.vue?vue&type=script&lang=ts&"
export * from "./TTypeColor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.0_vsyllalxesco42bbuzcqgel7xq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7e9500",
  null
  
)

export default component.exports