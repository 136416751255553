import Vue from 'vue';
import app from "@/app";
import {isFunction} from "oweb";

export const getUniqueId = (function () {
	let count = 0;
	return function getUniqueId() {
		return (count += 1) + '-' + +Date.now();
	};
})();

export const toUrl = function toUrl(path: string) {
	const baseUrl = app.configs.get('EDITOR_WEBSITE_BASE_URL') as string;

	return `${baseUrl.replace(/\/$/, '')}/${path.replace(/^\//, '')}`;
};

export const ozFileUrl = function (item: TFile, quality: 0 | 1 | 2 | 3 = 0) {
	return `/oz-static/uicn-${item.id}-${item.key}-${quality}`;
};
export const widgetAcceptChildren = function (wgt: TPageWidgetEntry | TWidget) {
	const opt = wm.get(wgt.name).options;
	return Boolean(opt.children);
};
export const buildNewItem = function (props: Record<string, any>) {
	const out: { id: string; data: Record<string, any> } = {
		id: getUniqueId(),
		data: {},
	};

	buildWgtFormOptions(props, {}).forEach(function (field) {
		out.data[field.name] = field.value;
	});

	return out;
};
export const buildWgtFormOptions = function (props: Record<string, any>, data: any) {
	const opt: Array<{
		name: string;
		options: Record<string, any>;
		value: any;
	}> = [];

	Object.keys(props).forEach(function (key) {
		const p = wm.copy(props[key]);
		const type = typesWidgets.get(p.type);
		const typeOptions = wm.copy(type.options);

		opt.push({
			name: key,
			options: Object.assign({}, typeOptions, p),
			value: key in data ? data[key] : p['default']
		});
	});

	return opt;
};

export const typesWidgets = (function () {
	const list: Record<string, TType> = {};
	return {
		add: function (type: TType) {
			if (list[type.name]) {
				throw new Error(`Type "${type.name}" cannot be overwritten.`);
			}

			list[type.name] = type;

			return this;
		},
		get: function (name: string): TType {
			if (!list[name]) {
				throw new Error(`Type "${name}" is not defined.`);
			}

			return list[name];
		},
	};
})();

export const wm = (function () {
	const list: Record<string, TWidgetFull> = {};
	let wgtId = 0;

	return {
		delay: (fn: () => any, delay: number) => {
			let first_run = true,
				val: any = undefined,
				tout: null | number = null;

			return function (this: any) {
				const ctx = this,
					args: any = arguments;

				if (first_run) {
					first_run = false;
					return (val = fn.apply(ctx, args));
				}

				null !== tout && clearTimeout(tout);
				tout = setTimeout(() => (val = fn.apply(ctx, args)), delay);

				return val;
			};
		},
		copy: function (data: any) {
			// not good but
			return JSON.parse(JSON.stringify(data));
		},
		add: function (wgt: TWidget) {
			if (list[wgt.name]) {
				throw new Error(`Widget "${wgt.name}" cannot be overwritten.`);
			}
			list[wgt.name] = {
				...wgt,
				id: 'wgt_' + ++wgtId,
			};

			const tc = typeof wgt.component;

			// make sure we are not dealing with lazy loaded component
			// or a globally registered component name string
			if (tc !== 'function' && tc !== 'string') {
				Vue.component(wgt.component.name, wgt.component);
			}

			return this;
		},
		getAll: function () {
			return list;
		},
		get: function (name: string): TWidgetFull {
			if (!list[name]) {
				throw new Error(`Widget "${name}" is not defined.`);
			}

			return list[name];
		},
		widgetDefaultOptions: function (wgt: TWidget) {
			const opt: Record<string, any> = {},
				wgtOpt = wm.copy(wgt.options), // This prevents object passed by reference
				props = wgtOpt['props'];

			Object.keys(props).forEach(function (key) {
				opt[key] = props[key]['default'] || '';
			});

			return opt;
		},
		buildSelectOptions: function (placeholder: string, data: any) {
			const items = typeof data === 'string' ? JSON.parse(data) : data;
			const keys = Object.keys(items);
			const opt = [
				{
					label: placeholder || 'Aucun',
					value: '',
				},
			];

			keys.forEach((key) => opt.push({label: items[key], value: key}));

			return opt;
		},
	};
})();

export const parseMarkdown = function (md: string) {
	if (isFunction((window as any).marked)) {
		return (window as any).marked(md, {sanitize: false});
	}
	return md;
};