import {id as _id, logger, OPageRoute, OPageRouteFull, OWebEvent, OWebPager, OWebRouteContext,} from 'oweb';
import app, {CustomPage} from '@/app';

interface PageDecoratorOptions {
	name: string;
	routes: OPageRoute[];
	install?: (pager: OWebPager<CustomPage>) => void;
	requireLogin?: (context: OWebRouteContext, route: OPageRouteFull) => boolean;
	onOpen?: (context: OWebRouteContext, route: OPageRouteFull) => void;
	onClose?: (oldRoute: OPageRouteFull, newRoute: OPageRouteFull) => void;
}

export default class PageBase extends OWebEvent implements CustomPage {
	constructor(
		public component: TComponent,
		private options: PageDecoratorOptions = {} as any
	) {
		super();

		this.options.install && this.on('install', this.options.install);
		this.options.onOpen && this.on('open', this.options.onOpen);
		this.options.onClose && this.on('close', this.options.onClose);
	}

	get name() {
		return this.options.name;
	}

	get routes() {
		return this.options.routes;
	}

	install(pager: OWebPager<this>): this {
		this.trigger('install', [pager], true);
		return this;
	}

	onOpen(context: OWebRouteContext, route: OPageRouteFull): void {
		this.trigger('open', [context, route], true);
	}

	onClose(oldRoute: OPageRouteFull, newRoute: OPageRouteFull): void {
		this.trigger('close', [oldRoute, newRoute], true);
	}

	requireLogin(context: OWebRouteContext, route: OPageRouteFull) {
		return this.options.requireLogin
			? this.options.requireLogin(context, route)
			: false;
	}
}

export const pageEvent = function (event: 'open' | 'close' | 'install') {
	return function (
		target: TComponent,
		name: string,
		descriptor: PropertyDescriptor
	) {
		let value = descriptor.value;
		logger.log('[PageDecorator] pageEvent', event, target, name, descriptor);
		if (typeof value !== 'function') {
			throw new Error(
				`Page event handler "${target}.${name}" should be a valid function not "${typeof value}".`
			);
		}
	};
};

export const pageRoutes = function (
	target: object,
	name: string,
	descriptor: PropertyDescriptor
) {
	let value = descriptor.value;
	logger.log('[PageDecorator] pageRoutes', target, name, descriptor);
	if (typeof value !== 'function') {
		throw new Error(
			`Page routes "${target}.${name}" should be an array not "${typeof value}".`
		);
	}
};

const pagesCache: { [key: string]: CustomPage } = {};
const keyName = '__OWEB_PAGE_KEY__';

export const registerPage = (
	component: TComponent,
	options: PageDecoratorOptions
) => {
	let page = getPageFor(component, options);

	app.pager.registerPage(page);
	return page;
};
const getPageFor = (component: TComponent, options: PageDecoratorOptions) => {
	let id = (component as any)[keyName];

	if (!id) {
		id = (component as any)[keyName] = _id();
		pagesCache[id] = new PageBase(component, options);
	}

	return pagesCache[id];
};

export const Page = function (options: PageDecoratorOptions) {
	return function <C extends TComponent>(component: C): C {
		registerPage(component, options);

		return component;
	};
};
