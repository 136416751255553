
import {buildNewItem} from '@/editor/utils';
import Vue from 'vue';
import TTypeBase from '@/editor/components/types/TTypeBase.vue';
import WEditorWidgetOptions from '@/editor/components/WEditorWidgetOptions.vue';
import {isEmpty} from "oweb";
import {Component} from "vue-property-decorator";
import WTippy from "@/components/WTippy.vue";

type TListItem = { id: string; data: Record<string, any> };
@Component({components: {WTippy, WEditorWidgetOptions},})
export default class TTypeList extends TTypeBase {

	activeItem: TListItem | null = null;
	shortIdMap: { [key: string]: number } = {};

	get tippyOptions() {
		return {
			interactive: true,
			arrow: true,
			theme: 'light',
			trigger: 'click',
			placement: 'right',
		};
	}

	get items() {
		return this.m_value;
	}

	get itemProps() {
		return this.o.options.typeProps.itemProps;
	}

	get itemName() {
		return this.o.options.typeProps.itemName;
	}

	get min() {
		return this.o.options.typeProps.min || 0;
	}

	get max() {
		return this.o.options.typeProps.max || Infinity;
	}

	getItemName(item: TListItem) {
		let path = this.itemName.split('.');
		let head = item.data;
		for (let i = 0; i < path.length; i++) {
			head = head[path[i]];
			if (isEmpty(head)) {
				break;
			}
		}
		return head || `${this.itemName} # ${this.getItemShortId(item)}`;
	}

	getItemShortId(item: TListItem) {
		if (this.shortIdMap[item.id]) {
			return this.shortIdMap[item.id];
		}
		let index = this.getItemIndex(item) as number;

		return (this.shortIdMap[item.id] = index + 1);
	}

	setActiveItem(item: TListItem) {
		this.activeItem = item;
	}

	clearActiveItem() {
		this.activeItem = null;
	}

	onItemsListChange() {
	}

	isActiveItem(item: TListItem) {
		return this.activeItem === item;
	}

	addNew() {
		if (this.items.length < this.max) {
			const item = buildNewItem(this.itemProps);
			this.items.push(item);
			this.activeItem && this.setActiveItem(item);
			this.onItemsListChange();
		}
	}

	getItemIndex(item: TListItem) {
		let index = null;
		for (let i = 0; i < this.items.length; i++) {
			const c = this.items[i];
			if (c.id === item.id) {
				index = i;
				break;
			}
		}

		return index;
	}

	removeItem(item: TListItem) {
		if (this.items.length > this.min) {
			const index = this.getItemIndex(item);
			if (!(index === null)) {
				this.clearActiveItem();
				this.items.splice(index, 1);
				this.onItemsListChange();
			}
		}
	}

	moveItemUp(item: TListItem) {
		const index = this.getItemIndex(item);

		if (!(index === null) && index > 0) {
			const tmp = this.items[index - 1];
			Vue.set(this.items, index - 1, item);
			Vue.set(this.items, index, tmp);
		}
	}

	moveItemDown(item: TListItem) {
		const index = this.getItemIndex(item);

		if (!(index === null) && index < this.items.length - 1) {
			const tmp = this.items[index + 1];
			Vue.set(this.items, index + 1, item);
			Vue.set(this.items, index, tmp);
		}
	}

	canGoUp(item: TListItem) {
		const index = this.getItemIndex(item);
		return !(index === null) && index > 0;
	}

	canGoDown(item: TListItem) {
		const index = this.getItemIndex(item);
		return !(index === null) && index < this.items.length - 1;

	}

	mounted() {
		if (this.items.length < this.min) {
			const diff = this.min - this.items.length;

			for (let i = 0; i < diff; i++) {
				this.items.push(buildNewItem(this.itemProps));
			}
		}
	}
}

