import {OWebApp, OWebServiceStore} from 'oweb';
import {UCMessage} from '@/gobl';

export default class MessagesStore extends OWebServiceStore<UCMessage> {
	public static readonly MESSAGE_TYPE_TEXT = 'TEXT';
	public static readonly MESSAGE_TYPE_ATTACHMENT = 'ATTACHMENT';

	constructor(app: OWebApp) {
		super(app, UCMessage, 'messages');
	}
}
