import {logger, OPageRouteFull, OViewDialog, OWebApp, OWebRouteContext,} from 'oweb';
import servicesBundle from '@/services';
import customStore from '@/store';
import {CustomPage} from '@/app';

const storeBundle = function (this: OWebApp) {
	logger.log('[App] store bundle created for %s.', this.getAppName());
	let page: CustomPage = undefined as any,
		route: OPageRouteFull = undefined as any,
		routeContext: OWebRouteContext = undefined as any;

	return {
		ready: false,
		splash: true,
		frozen: false,
		page,
		route,
		routeContext,
		dialogs: [] as OViewDialog[],
		// for reactivity
		services: servicesBundle.call(this),
		// custom store
		...customStore.call(this),
	};
};

export default storeBundle;
