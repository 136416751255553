
import TTypeBase from '@/editor/components/types/TTypeBase.vue';

export default TTypeBase.extend({
	name: 'TTypeUrl',
	methods: {
		validate: function () {
			let url = this.m_value.url || this.c_value.url;
			const reg = new RegExp('^https?://' + location.host + '/?', 'i');

			if (reg.test(url)) {
				url = url.replace(reg, '/');
			}

			const label = this.m_value.label || this.c_value.label;

			this.m_value = {url, label};
		},
	},
});
