
/**
 * Auto generated file
 *
 * WARNING: please don't edit.
 *
 * Proudly With: gobl v1.5.0
 * Time: 1651227193
 */

import {
	GoblEntity,
	_bool,
	_int,
	_string,
} from 'gobl-utils-ts';

export default abstract class UCChannelJoinBase extends GoblEntity {
	public static readonly PREFIX: string = 'cj';
	public static readonly COLUMNS: string[] = [
	'cj_id',
	'cj_user_id',
	'cj_channel_id',
	'cj_type',
	'cj_status',
	'cj_custom_data',
	'cj_create_time',
	'cj_update_time',
	'cj_valid',

	];
	public static readonly COL_ID: string = 'cj_id';
	public static readonly COL_USER_ID: string = 'cj_user_id';
	public static readonly COL_CHANNEL_ID: string = 'cj_channel_id';
	public static readonly COL_TYPE: string = 'cj_type';
	public static readonly COL_STATUS: string = 'cj_status';
	public static readonly COL_CUSTOM_DATA: string = 'cj_custom_data';
	public static readonly COL_CREATE_TIME: string = 'cj_create_time';
	public static readonly COL_UPDATE_TIME: string = 'cj_update_time';
	public static readonly COL_VALID: string = 'cj_valid';

	public singlePKValue() {
		return this.id;
	}
	public identifierColumns() {
		return [ UCChannelJoinBase.COL_ID ];
	}
	get id() { return _string(this._data[UCChannelJoinBase.COL_ID]); }
	set id(nVal: any) { this._set(UCChannelJoinBase.COL_ID, _string(nVal)); }
	public getId() { return this.id; }
	public setId(nVal: any): this { this.id = nVal; return this; }

	get user_id() { return _string(this._data[UCChannelJoinBase.COL_USER_ID]); }
	set user_id(nVal: any) { this._set(UCChannelJoinBase.COL_USER_ID, _string(nVal)); }
	public getUserId() { return this.user_id; }
	public setUserId(nVal: any): this { this.user_id = nVal; return this; }

	get channel_id() { return _string(this._data[UCChannelJoinBase.COL_CHANNEL_ID]); }
	set channel_id(nVal: any) { this._set(UCChannelJoinBase.COL_CHANNEL_ID, _string(nVal)); }
	public getChannelId() { return this.channel_id; }
	public setChannelId(nVal: any): this { this.channel_id = nVal; return this; }

	get type() { return _string(this._data[UCChannelJoinBase.COL_TYPE]); }
	set type(nVal: any) { this._set(UCChannelJoinBase.COL_TYPE, _string(nVal)); }
	public getType() { return this.type; }
	public setType(nVal: any): this { this.type = nVal; return this; }

	get status() { return _string(this._data[UCChannelJoinBase.COL_STATUS]); }
	set status(nVal: any) { this._set(UCChannelJoinBase.COL_STATUS, _string(nVal)); }
	public getStatus() { return this.status; }
	public setStatus(nVal: any): this { this.status = nVal; return this; }

	get custom_data() { return _string(this._data[UCChannelJoinBase.COL_CUSTOM_DATA]); }
	set custom_data(nVal: any) { this._set(UCChannelJoinBase.COL_CUSTOM_DATA, _string(nVal)); }
	public getCustomData() { return this.custom_data; }
	public setCustomData(nVal: any): this { this.custom_data = nVal; return this; }

	get create_time() { return _string(this._data[UCChannelJoinBase.COL_CREATE_TIME]); }
	set create_time(nVal: any) { this._set(UCChannelJoinBase.COL_CREATE_TIME, _string(nVal)); }
	public getCreateTime() { return this.create_time; }
	public setCreateTime(nVal: any): this { this.create_time = nVal; return this; }

	get update_time() { return _string(this._data[UCChannelJoinBase.COL_UPDATE_TIME]); }
	set update_time(nVal: any) { this._set(UCChannelJoinBase.COL_UPDATE_TIME, _string(nVal)); }
	public getUpdateTime() { return this.update_time; }
	public setUpdateTime(nVal: any): this { this.update_time = nVal; return this; }

	get valid() { return _bool(this._data[UCChannelJoinBase.COL_VALID]); }
	set valid(nVal: any) { this._set(UCChannelJoinBase.COL_VALID, _bool(nVal)); }
	public getValid() { return this.valid; }
	public setValid(nVal: any): this { this.valid = nVal; return this; }


}
