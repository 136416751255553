
import {wm} from '../utils';
import BaseComponent from '@/components/BaseComponent.vue';

export default BaseComponent.extend({
	name: 'WEditorWidgets',
	mixins: [BaseComponent],
	methods: {
		wDefaultOptions(wgt: TWidget) {
			return wm.widgetDefaultOptions(wgt);
		},
	},
});
