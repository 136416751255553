
import BaseComponent from '@/components/BaseComponent.vue';
import {isEmpty, isString} from "oweb";

export default BaseComponent.extend({
	name: 'TTypeBase',
	props: {
		o: {
			required: true,
			type: Object,
		}
	},
	watch: {
		m_value: {
			deep: true,
			handler: function (nv) {
				this.updateValue(nv);
			},
		},
		o: {
			deep: true,
			handler: function (nv) {
				this.m_value = nv.value;
			},
		},
	},
	data: function () {
		return {
			m_value: this.o.value,
			c_value: JSON.parse(JSON.stringify(this.o.value)),
		};
	},
	methods: {
		updateValue: function (v: any) {
			this.$emit('update-value', v);
		},
		validate: function () {
			if (isEmpty(this.m_value) && !isString(this.m_value)) {
				this.updateValue(this.c_value);
			}
		},
	},
});
