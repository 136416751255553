import Vue from 'vue';
import app from '@/app';
import {DirectiveBinding} from 'vue/types/options';

Vue.directive('o-focus', {
	inserted(el: HTMLElement, binding: DirectiveBinding) {
		if (binding.value) {
			el.focus();
		}
	},
});

Vue.directive('i18n', {
	inserted(el: HTMLElement, binding: DirectiveBinding) {
		binding.value && app.i18n.el(el, binding.value);
	},
	update(el: HTMLElement, binding: DirectiveBinding) {
		binding.value && app.i18n.el(el, binding.value);
	},
});
