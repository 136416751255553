import {OPageRouteFull, OViewDialog} from 'oweb';
import app, {CustomPage} from '@/app';

app.pager.onLocationChange((route: OPageRouteFull, page: CustomPage) => {
	app.store.page = page;
	app.store.route = route;
	app.store.routeContext = app.router.getRouteContext();
});

app.view
	.onFreeze(() => {
		app.store.frozen = true;
	})
	.onUnFreeze(() => {
		app.store.frozen = false;
	})
	.onDialog((dialog: OViewDialog, can_use_alert: boolean) => {
		let {text, data} = dialog;

		if (text && text.length) {
			if (can_use_alert && app.isMobileApp()) {
				alert(app.i18n.toHuman(text, data));
			} else {
				app.store.dialogs.push(dialog);
			}
		}
	});
