
/**
 * Auto generated file,
 *
 * INFO: you are free to edit it,
 * but make sure to know what you are doing.
 *
 * Proudly With: gobl v1.5.0
 * Time: 1650839726
 */

import { getEntityCache, GoblEntityData } from "gobl-utils-ts";
import OZAdminBase from "./base/OZAdminBase";

export default class OZAdmin extends OZAdminBase {
	constructor(data?: GoblEntityData) {
		super(data, "OZAdmin", OZAdmin.PREFIX, OZAdmin.COLUMNS);
	}

	public static fromCache(cacheKey: string): OZAdmin | undefined {
		const cache: any = getEntityCache("OZAdmin");
		return cache && cache[cacheKey];
	}

	//====================================================
	//=	Your custom implementation goes here
	//====================================================
}
