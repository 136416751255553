import {registerPage} from '@/utils/decorators';
import app from "@/app";

registerPage(() => import('@/pages/PLogin.vue'), {
	name: 'PLogin',
	routes: [
		{
			title: 'OO_LANG_LOGIN',
			path: '/login',
			show: false,
		},
	],
});
registerPage(() => import('@/pages/PSignup.vue'), {
	name: 'PSignup',
	routes: [
		{
			title: 'OO_LANG_REGISTER',
			path: '/signup',
			show: false,
		},
	],
});

registerPage(() => import('@/pages/PAdmin.vue'), {
	name: 'PAdmin',
	routes: [
		{
			title: 'UX_LANG_ADMIN_PAGE',
			path: '/admin',
			show: false,
		},
		{
			title: 'UX_LANG_ADMIN_PAGE',
			path: '/admin/:section',
			show: false,
		},
	],
	requireLogin() {
		return true;
	},
});


registerPage(() => import('@/pages/PForum.vue'), {
	name: 'PForum',
	routes: [
		{
			title: 'UX_LANG_FORUM',
			path: '/forum',
			show: false,
		},
		{
			title: 'UX_LANG_FORUM',
			path: '/forum/:section',
			show: false,
		},
	],
	requireLogin() {
		return true;
	},
});

registerPage(() => import('@/pages/PEditor.vue'), {
	name: 'PEditor',
	routes: [
		{
			title: 'UX_LANG_EDITOR',
			path: '/editor',
			show: false,
		}, {
			title: 'UX_LANG_EDITOR',
			path: '/editor/:pageId/edit',
			pathOptions: {
				pageId: 'num'
			},
			show: false,
		},
	],
	requireLogin() {
		return true;
	},
	onClose() {
		app.store.editorRoot.closeEditor();
	}
});

registerPage(() => import('@/pages/PDevHome.vue'), {
	name: 'PHome',
	routes: [
		{
			title: 'UX_LANG_PAGE_TITLE_DEFAULT',
			path: (process.env.NODE_ENV === 'production') ? '/home-dev' : '/',
			show: false,
		},
	],
});


const pageSlug = /[a-zA-Z0-9-_]+/,
	pageRef = /[a-zA-Z0-9-_]+/,
	pageId = 'num';

registerPage(() => import('@/pages/PWebSite.vue'), {
	name: 'PWebSite',
	routes: [
		{
			title: 'UX_LANG_PAGE_TITLE_DEFAULT',
			path: '/pages/:pageId/:pageSlug',
			pathOptions: {
				pageSlug,
				pageId
			},
			show: false,
		},
		{
			title: 'UX_LANG_PAGE_TITLE_DEFAULT',
			path: '/:pageSlug~:pageRef',
			pathOptions: {
				pageSlug,
				pageRef
			},
			show: false,
		},
		{
			title: 'UX_LANG_PAGE_TITLE_DEFAULT',
			path: '/:pageSlug',
			pathOptions: {
				pageSlug
			},
			show: false,
		},
		{
			title: 'UX_LANG_PAGE_TITLE_DEFAULT',
			path: '/',
			show: false,
		}
	],
});