import {OWebApp, OWebLogout, OWebServiceStore} from 'oweb';
import app from '@/app';
import {OZUser} from '@/gobl';

export default class UsersStore extends OWebServiceStore<OZUser> {
	private busy = false;
	private user_data_cb: any[] = [];

	constructor(app: OWebApp) {
		super(app, OZUser, 'users');
		this.scheduleLoad(1000);
	}

	scheduleLoad(time: number = 5000) {
		let m = this;
		let timer = setInterval(function () {
			if (app.user.isVerified()) {
				clearInterval(timer);
				m.loadCurrentUserData();
			}
		}, time);
	}

	isAdmin() {
		let infos = this.userInfos();
		return infos && infos['is_admin'];
	}

	logout() {
		const app = this._appContext;
		app.view.freeze();

		let logout = new OWebLogout(app);
		logout
			.onLogoutSuccess(function () {
				setTimeout(function () {
					app.destroyApp();
				});
			})
			.onLogoutFail(function (err) {
				app.view.dialog(err);
				app.router.goBack();
			})
			.logout()
			.finally(() => app.view.unfreeze());
	}

	userInfos() {
		let user = app.user.current;
		return (user && this.itemRelation<any>(user, 'infos')) || {};
	}

	getNewslettersCount(): number {
		return this.userInfos()['newsletters_count'] || 0;
	}

	getUsersCount(): number {
		return this.userInfos()['users_count'] || 0;
	}

	getChannelsCount(): number {
		return this.userInfos()['channels_count'] || 0;
	}

	getPagesCount(): number {
		return this.userInfos()['pages_count'] || 0;
	}

	loadCurrentUserData(then?: (success: boolean) => void) {
		then && this.user_data_cb.push(then);

		if (!this.busy) {
			this.busy = true;
			let m = this,
				user: OZUser = app.user.current!;

			const req = this.getItem(user.getId(), 'infos');
			req.send().then(
				function () {
					m.runCb(true);
					m.busy = false;
					m.scheduleLoad(60000);
				},
				function () {
					m.runCb(false);
					m.busy = false;
					m.scheduleLoad(10000);
				}
			);
		} else {
			console.warn('[UsersStore] busy!');
		}
	}

	private runCb(success: boolean) {
		this.user_data_cb.forEach((fn) => fn(success));
		this.user_data_cb = [];
	}
}
