
import WWidgetBase from '@/editor/components/widgets/WWidgetBase.vue';

export default WWidgetBase.extend({
	name: 'WWidgetBaseWithChildren',
	props: {
		children: {
			required: true,
			type: Array,
		},
	},
	computed: {
		typedChildren() {
			return this.children as TPageWidgetEntry[];
		},
	},
});
