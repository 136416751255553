
import BaseComponent from '@/components/BaseComponent.vue';

export default BaseComponent.extend({
	name: 'WWidgetBase',
	props: {
		o: {
			required: true,
			type: Object,
		},
		children: {
			required: false,
			type: Array,
		},
	},
	methods: {
		formatPrice: function (amount: string) {
			return Number(amount).toLocaleString('fr-FR', {
				style: 'currency',
				currency: 'XOF',
			});
		},
		formatDate: function (date: string) {
			var d = new Date(date);
			var digits = (d: number) => (d < 10 ? '0' + d : d);

			return (
				digits(d.getDate()) +
				'/' +
				digits(d.getMonth() + 1) +
				'/' +
				digits(d.getFullYear())
			);
		},
		notEmptyStr: function (str: string) {
			return String(str || '').trim().length > 0;
		},
		notEmptyLink: function (link: TUrl) {
			return (
				link && String(link.url || '').trim().length > 0 && link.url !== '#'
			);
		},
		openLinkIfValid: function (link: TUrl) {
			if (this.notEmptyLink(link)) {
				window.location = link.url as any as Location;
			}
		}
	},
});
